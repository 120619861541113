import React from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,Grid
} from "@mui/material";
import { useAuthCheck } from "../utils/Auth";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:"#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const staticData = [
  { machine: 5, dateTime: "2024-03-31 13:54:36.068", cycleTime: 16.196 },
  { machine: 5, dateTime: "2024-03-31 13:54:19.872", cycleTime: 16.202 },
  { machine: 5, dateTime: "2024-03-31 13:54:03.670", cycleTime: 32.399 },
  { machine: 5, dateTime: "2024-03-31 13:53:31.271", cycleTime: 16.197 },
  { machine: 5, dateTime: "2024-03-31 13:53:15.074", cycleTime: 32.4 },
  { machine: 5, dateTime: "2024-03-31 13:52:42.674", cycleTime: 16.204 },
  { machine: 5, dateTime: "2024-03-31 13:52:26.470", cycleTime: 16.297 },
  { machine: 5, dateTime: "2024-03-31 13:52:10.173", cycleTime: 16.298 },
  { machine: 5, dateTime: "2024-03-31 13:51:53.875", cycleTime: 16.099 },
  { machine: 5, dateTime: "2024-03-31 13:51:37.776", cycleTime: 32.3 },
  { machine: 5, dateTime: "2024-03-31 13:51:05.476", cycleTime: 16.401 },
  { machine: 5, dateTime: "2024-03-31 13:50:49.075", cycleTime: 16.299 },
  { machine: 5, dateTime: "2024-03-31 13:50:32.776", cycleTime: 32.3 },
  { machine: 5, dateTime: "2024-03-31 13:50:00.476", cycleTime: 32.502 }
];

export default function Rawghaziabad() {
  useAuthCheck();
  return (
    <div style={{ padding: "0px 20px", width: "100%",  }}>
        <div style={{display:'flex',justifyContent:'flex-start',paddingTop: "5px", paddingBottom: "10px"}}>
          <h2>Raw Data Ghaziabad</h2>
        </div>
      <Grid container spacing={2} style={{ width: "100%", alignItems: "center"}}> {/* Set alignItems to center items vertically */}
        <Grid item xs={6} sm={3}> {/* Adjust item size for different screen sizes */}
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Machine</InputLabel>
            <Select defaultValue="1">
              <MenuItem value={1}>Machine 1</MenuItem>
              <MenuItem value={2}>Machine 2</MenuItem>
              <MenuItem value={3}>Machine 3</MenuItem>
              <MenuItem value={4}>Machine 4</MenuItem>
              <MenuItem value={5}>Machine 5</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}> {/* Adjust item size for different screen sizes */}
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="Start Date"
              type="datetime-local"
              defaultValue="2024-03-20T09:00"
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}> {/* Adjust item size for different screen sizes */}
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="End Date"
              type="datetime-local"
              defaultValue="2024-03-20T09:00"
            />
          </FormControl>
        </Grid>
        <Grid item > {/* Adjust item size for different screen sizes */}
          <Button variant="contained" color="primary" >
            OK
          </Button>
        </Grid>
      </Grid>
      {/* <div style={{  display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "20px"}}>
      
      <FormControl sx={{ minWidth: 250 }}>

            <TextField
              label="Search"
              type="search"
              id="fullWidth"
              placeholder="Search"
              fullWidth
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon icon={faSearch} />
                ),
              }}
              variant="outlined"
         
              //   value={searchTerm}
              //   onChange={(e) => setSearchTerm(e.target.value)}
              />
            {"   "}
            </FormControl>
          <Button sx={{ marginLeft: "10px" }} variant="contained" color="primary" >
            GO
          </Button>
          </div> */}
           <Box>
            <Table size="small" style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" ,marginTop:'20px' }} >
              <TableHead>
                <TableRow>
                <StyledTableCell  className="table-cell">Machine </StyledTableCell >
                <StyledTableCell  className="table-cell">Date  Time</StyledTableCell >
                <StyledTableCell  className="table-cell">Cycle  Time</StyledTableCell >
                </TableRow>
              </TableHead>
              <TableBody>
              {staticData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{row.machine}</StyledTableCell>
                <StyledTableCell>{row.dateTime}</StyledTableCell>
                <StyledTableCell>{row.cycleTime}</StyledTableCell>
              </StyledTableRow>
            ))}
              </TableBody>
            </Table>
          </Box>
    </div>
  );
}
