import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import CBM from "../components/cbm";
import Operations from "../components/operations";
import Sidebar from "../components/sidebar";
import PartMaster from "../components/partmaster";
// import SignInSide from '../components/Login'
import Login from "../components/Login";
import PathrediOpe from "../components/pathrediope";
import GhaziabadOpe from "../components/ghaziabadope";
import UATRawData from "../components/UATRawData";
import UATReport from "../components/UATReport";
import UATTesting from "../components/UATTesting";
import FacilityManagement from "../components/facilitymanagement";
import Rawghaziabad from "../components/Rawghaziabad";
import Rawpathrediope from "../components/Rawpathrediope copy";
import MachineMaster from "../components/machinemaster";
import LineMaster from "../components/linemaster";
import PlantMaster from "../components/plantmaster";
import FrdcMaster from "../components/frdcmaster";
import Temp from "../components/temp";
import MachineDownTime from "../components/machinedowntime";
import QualityRejection from "../components/qualityrejection";
import ShiftMaster from "../components/shiftmaster";
import GhaziabadOpe2 from "../components/ghaziabadope2";
import PathrediOpe2 from "../components/pathrediope2";
import MachineInput from "../components/machineinput";
import ChangeOverMaster from "../components/changeovermaster";
import FacilitySustainability from "../components/facilitysustainability";
import HourlyBucketM1 from "../components/hourlybucket_m1";
import HourlyBucketM2 from "../components/hourlybucket_m2";

import EnergyManagement from "../components/energymanagement";
import BasicGauges from "../components/chartssize";
import AirQualityManagement from "../components/airqualitymanagement";
import DieselGenset from "../components/dieselgenset";
import STPManagement from "../components/stpmanagement";
import EnlargedChartPage from "../components/fullviewchart";
import WaterManagement from "../components/watermanagement";
import Welcome from "../components/welcome";
import DailyReportM1 from "../components/dailyreportm1";
import DailyReportM2 from "../components/dailyreportm2";
import WeeklyReportM1 from "../components/weeklyreportm1";
import WeeklyReportM2 from "../components/weeklyreportm2";
import MonthlyReportM1 from "../components/monthlyreportm1";
import MonthlyReportM2 from "../components/monthlyreportm2";
import QuaterlyReportM1 from "../components/quaterlyreportm1";
import QuaterlyReportM2 from "../components/quaterlyreportm2";
import YearlyReportM1 from "../components/yearlyreportm1";
import YearlyReportM2 from "../components/yearlyreportm2";
import UnknownLoss from "../components/unknownloss";
import COntrolRoom from "../components/controlroom";
import WholeDayReportM1 from "../components/wholedayreportm1";
import WholeDayReportM2 from "../components/wholedayreportm2";
import ChartComponent from "../components/charts";
import HolidayList from "../components/holidaylist";
import OEEEDashboard from "../components/oeedashboard";
import SmartDashboard from "../components/smartdashboard";
import ProductionGraphChart from "../components/ProductionGraph";
import HeatmapChart from "../components/ProductionGraph";
import IconicDashboard from "../components/IconicViewDashboard";
import TabularViewMP from "../components/TabularViewMP";
import GanttChartComponent from "../components/MachineStatusChat";
// import MachineStatusChart from "../components/MachineStatusChat";
// import GanttChart from "../components/MachineStatusChat";

// import MachineStatusGanttChart from "../components/MachineStatusChat";


const data = [
  {
    name: 'VMC-65',
    activities: [
      { status: 'off', duration: 10 },
      { status: 'on', duration: 90 },
      { status: 'on', duration: 90 },
      { status: 'off', duration: 30 },
      { status: 'on', duration: 10 },

    ]
  },
  {
    name: 'VMC-64',
    activities: [
      { status: 'off', duration: 5 },
      { status: 'on', duration: 45 },
      { status: 'off', duration: 80 },
      { status: 'on', duration: 20 },
      { status: 'on', duration: 30 },

    ]
  },
  {
    name: 'VMC-63',
    activities: [
      { status: 'off', duration: 5 },
      { status: 'on', duration: 45 },
      { status: 'off', duration: 10 },
      { status: 'on', duration: 20 },
      { status: 'on', duration: 40 },

    ]
  },
  {
    name: 'VMC-62',
    activities: [
      { status: 'off', duration: 5 },
      { status: 'on', duration: 45 },
      { status: 'off', duration: 45 },

      { status: 'on', duration: 30 },
      { status: 'on', duration: 20 },
      { status: 'on', duration: 80 },
      { status: 'off', duration: 80 },

    ]
  },
  {
    name: 'VMC-61',
    activities: [
      { status: 'off', duration: 5 },
      { status: 'on', duration: 45 },
      { status: 'off', duration: 30 },
      { status: 'on', duration: 20 },
    ]
  },
  {
    name: 'VMC-70',
    activities: [
      { status: 'off', duration: 5 },
      { status: 'on', duration: 5 },
      // { status: 'off', duration: 30 },
      // { status: 'on', duration: 20 },
    ]
  },

];
const startTime = new Date('2024-02-07T07:00:00');
const endTime = new Date('2024-02-07T19:00:00');



// const data = [
//   {
//     times: [
//       "2023-08-07T10:00:00",
//       "2023-08-07T10:15:00",
//       "2023-08-07T10:30:00",
//     ],
//     data: [0, 1, 0],
//   },
//   {
//     times: [
//       "2023-08-07T10:00:00",
//       "2023-08-07T10:15:00",
//       "2023-08-07T10:30:00",
//     ],
//     data: [1, 0, 1],
//   },
//   {
//     times: [
//       "2023-08-07T10:00:00",
//       "2023-08-07T10:15:00",
//       "2023-08-07T10:30:00",
//     ],
//     data: [1, 0, 1],
//   },
//   {
//     times: [
//       "2023-08-07T10:00:00",
//       "2023-08-07T10:15:00",
//       "2023-08-07T10:30:00",
//     ],
//     data: [1, 0, 1, 0, 1],
//   },
  
// ];

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/cbm" element={<CBM />} />
      <Route path="/operations" element={<Operations />} />
      <Route path="/sidebar" element={<Sidebar />} />
      {/* <Route path='/partmaster' element={<PartMaster/>}/> */}
      <Route path="/login" element={<Login />} />
      <Route path="/smartdashboard" element={<SmartDashboard />} />
      <Route path="" element={<Login />} />
      <Route path="/pathredi_ope" element={<PathrediOpe />} />
      <Route path="/uat/uat_rawdata" element={<UATRawData />} />
      <Route path="/uat/uat_reports" element={<UATReport />} />
      <Route path="/uat/uat_testing" element={<UATTesting />} />
      <Route path="/raw_data" element={<Rawpathrediope />} />
      <Route path="/hour_bucket_m1" element={<HourlyBucketM1 />} />
      <Route path="/hour_bucket_m2" element={<HourlyBucketM2 />} />
      <Route path="/raw_data/uat_ghaziabad" element={<Rawghaziabad />} />
      <Route path="/ghaziabad_ope" element={<GhaziabadOpe />} />
      <Route path="/facilitymanagement" element={<FacilityManagement />} />
      <Route path="/plant_architecture/plant_master" element={<LineMaster />} />
      {/* <Route
        path="/plant_architecture/plant_master"
        element={<PlantMaster />}
      /> */}
      {/* <Route path="/plant_architecture/frdc_master" element={<FrdcMaster/>}/> */}
      <Route path="/plant_architecture/part_master" element={<PartMaster />} />
      <Route
        path="/production_plan/machine_downtime"
        element={<MachineDownTime />}
      />
      <Route
        path="/production_plan/quality_rejection"
        element={<QualityRejection />}
      />
      <Route path="/production_plan/unknown_loss" element={<UnknownLoss />} />
      <Route
        path="/plant_architecture/device_status"
        element={<COntrolRoom />}
      />
      <Route path="/production_plan/shift_master" element={<ShiftMaster />} />
      <Route path="/method2/machine_input" element={<MachineInput />} />
      <Route path="/method2/ghaziabad_ope_2" element={<GhaziabadOpe2 />} />
      <Route path="/method2/pathredi_ope_2" element={<PathrediOpe2 />} />
      <Route path="/method1/pathredi_ope_1" element={<PathrediOpe />} />
      <Route path="/method1/ghaziabad_ope_1" element={<GhaziabadOpe />} />
      {/* <Route path="/plant_architecture/frdc_master" element={<ChangeOverMaster/>}/>   */}
      <Route
        path="/plant_architecture/frdc_master"
        element={<ChangeOverMaster />}
      />
      <Route
        path="/facilitysustainability"
        element={<FacilitySustainability />}
      />
      <Route path="/energymanagement" element={<EnergyManagement />} />
      <Route path="/airquality" element={<AirQualityManagement />} />
      <Route path="/dieselgenset" element={<DieselGenset />} />
      <Route path="/dashboard/oeedashboard" element={<OEEEDashboard />} />
      <Route path="/charts" element={<ChartComponent />} />
      <Route path="/temp" element={<Temp />} />
      <Route path="/basic" element={<BasicGauges />} />
      <Route path="/stpmanagement" element={<STPManagement />} />
      <Route path="/fullviewchart" element={<EnlargedChartPage />} />
      <Route path="/watermanagement" element={<WaterManagement />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/reportm1/daily" element={<DailyReportM1 />} />
      <Route path="/reportm1/hour_bucket_m1" element={<HourlyBucketM1 />} />
      <Route path="/reportm2/hour_bucket_m2" element={<HourlyBucketM2 />} />
      <Route path="/reportm2/daily" element={<DailyReportM2 />} />
      <Route path="/reportm1/wholedayreportm1" element={<WholeDayReportM1 />} />
      <Route path="/reportm2/wholedayreportm2" element={<WholeDayReportM2 />} />
      <Route path="/reportm1/weekly" element={<WeeklyReportM1 />} />
      <Route path="/production_plan/holiday_list" element={<HolidayList />} />
      <Route path="/reportm2/weekly" element={<WeeklyReportM2 />} />{" "}
      <Route path="/reportm1/monthly" element={<MonthlyReportM1 />} />
      <Route path="/reportm2/monthly" element={<MonthlyReportM2 />} />
      <Route path="/reportm1/quaterly" element={<QuaterlyReportM1 />} />
      <Route path="/reportm2/quaterly" element={<QuaterlyReportM2 />} />
      <Route path="/reportm1/yearly" element={<YearlyReportM1 />} />
      <Route path="/reportm2/yearly" element={<YearlyReportM2 />} />
      <Route path="/tempchart" element={<HeatmapChart />} />
      <Route path="/dashboard/iconicview" element={<IconicDashboard />} />
      <Route path="/dashboard/tabularview" element={<TabularViewMP />} />
      <Route path="/dashboard/machinestatus" element={<GanttChartComponent />} />


      <Route path="/productiongraphchart" element={<ProductionGraphChart data={data}   />} />
      <Route
        path="/plant_architecture/machine_master"
        element={<MachineMaster />}
      />
    </Routes>
  );
}
