import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, Typography, CircularProgress } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import "../assets/css/emanagement.css";
import { apiGetEnergyData } from "../api/api.getenergydata";
import BackButton from "./backbutton";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthCheck } from "../utils/Auth";

export default function EnergyManagement() {
  const [energyManagementData, setEnergyManagementData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [dataType, setDataType] = useState('shift');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const location = useLocation();
  const [initialLoad, setInitialLoad] = useState(true);
  const { locationId } = location.state || {};
  const navigate = useNavigate();

  useAuthCheck()
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  console.log("location id em:", locationId);

  const fetchData = async (locationId, dataType,showLoader = false) => {
    try {
      if (showLoader) {
        setIsLoading(true);
        setLoadingMessage(`Loading ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`);
      }
      const result = await apiGetEnergyData(locationId, dataType);
      const chunkedData = result.data.data.slice(0, 20);
      setEnergyManagementData(chunkedData);
      console.log("chunkeddata", chunkedData);

      const xAxisDates = chunkedData.map((item) => {
        const dateParts = item.dateTimeRecvd.split(' ');
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split('-');
        const [hours, minutes, seconds] = timePart.split(':');
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        return date;
      });

      console.log("newdate:", xAxisDates);

      // Sort the data by date in ascending order
      const sortedData = chunkedData.sort((a, b) => {
        const dateA = new Date(a.dateTimeRecvd.split(' ')[0].split('-').reverse().join('-') + 'T' + a.dateTimeRecvd.split(' ')[1]);
        const dateB = new Date(b.dateTimeRecvd.split(' ')[0].split('-').reverse().join('-') + 'T' + b.dateTimeRecvd.split(' ')[1]);
        return dateA - dateB;
      });

      const xAxisLabels = sortedData.map((item) => {
        const dateParts = item.dateTimeRecvd.split(' ');
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split('-');
        const [hours, minutes, seconds] = timePart.split(':');
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        return date.toLocaleString();
      });

      const updatedChartData = [
        {
          type: "line",
          xAxis: xAxisDates,
          data: sortedData.map((item) => parseFloat(item.voltageR)),
        },
        {
          type: "line",
          xAxis: xAxisDates,
          data: sortedData.map((item) => parseFloat(item.currentR)),
        },
        {
          type: "bar",
          xAxis: xAxisLabels,
          data: sortedData.map((item) => parseFloat(item.kwh)),
        },
        {
          type: "line",
          xAxis: xAxisDates,
          data: sortedData.map((item) => parseFloat(item.pf)),
        },
        {
          type: "line",
          xAxis: xAxisDates,
          data: sortedData.map((item) => parseFloat(item.kvah)),
        },
        {
          type: "line",
          xAxis: xAxisDates,
          data: sortedData.map((item) => parseFloat(item.currentR)),
        },
      ];

      console.log("updatedchartdata:", updatedChartData);
      setChartData(updatedChartData);
      if (showLoader) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching energy data:", error);
      if (showLoader) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (locationId) {
      fetchData(locationId, dataType, initialLoad);
      const intervalId = setInterval(() => fetchData(locationId, dataType), 10000);
      return () => clearInterval(intervalId);
    }
  }, [locationId, dataType, initialLoad]);




  // const handleChartClick = (chartType) => {
  //   navigate("/fullviewchart", {
  //     state: { locationId, chartType, dataType },
  //   });
  // };
  const handleChartClick = (chartType, chartName) => {
    const clickedChart = chartData.find(chart => chart.type === chartType);
    navigate("/fullviewchart", {
      state: { locationId, chartType, dataType, chartName, clickedChart },
    });
  };
  

  const handleButtonClick = (type) => {
    setDataType(type);
    setInitialLoad(true);
    fetchData(locationId, type, true);
    setInitialLoad(false);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} display={'flex'}>
          <BackButton />
          <Typography variant="h5" gutterBottom>
            Energy Management
          </Typography>
        </Grid>
        <Grid item xs={7} container justifyContent="flex-end">
          {renderButtons(handleButtonClick, dataType)}
        </Grid>

        {isLoading ? (
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" style={{ height: "300px" }}>
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                {loadingMessage}
              </Typography>
            </div>
          </Grid>
        ) : (
          chartData.map((chart, index) => (
            <Grid item xs={4} key={index} onClick={() => handleChartClick(chart.type, getChartTitle(chart, index))} style={{cursor:'pointer'}}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={getChartTitleStyle(chart, index)}
                >
                  {getChartTitle(chart, index)}
                </Typography>
                {renderChart(chart)}
              </Paper>
            </Grid>
          ))
          
        )}
      </Grid>
    </div>
  );
}

function getChartTitle(chart, index) {
  if (chart.type === "line") {
    switch (index) {
      case 0:
        return "Voltage (V)";
      case 1:
        return "Current (AMP)";
      case 3:
        return "Power Factor";
      case 4:
        return "kVAh";
      case 5:
        return "kVAR";
      default:
        return `Line Chart ${index + 1}`;
    }
  } else if (chart.type === "bar") {
    switch (index) {
      case 2:
        return "kWh";
      default:
        return `Bar Chart ${index + 1}`;
    }
  } else {
    return `Chart ${index + 1}`;
  }
}

function getChartTitleStyle(chart, index) {
  const defaultStyle = {
    color: "white",
    fontFamily: "Arial, sans-serif",
  };

  if (chart.type === "line") {
    switch (index) {
      case 0:
      case 1:
      case 3:
      case 4:
      case 5:
        return { ...defaultStyle, fontWeight: "bold" };
      default:
        return defaultStyle;
    }
  } else if (chart.type === "bar") {
    switch (index) {
      case 2:
        return { ...defaultStyle, fontWeight: "bold" };
      default:
        return defaultStyle;
    }
  } else {
    return defaultStyle;
  }
}

function renderChart(chart) {
  if (chart.type === "bar") {
    return (
      <BarChart
        xAxis={[{ scaleType: 'band', data: chart.xAxis }]}
        series={[{ data: chart.data }]}
        width={450}
        height={200}
      />
    );
  } else {
    return (
      <LineChart
        xAxis={[{ scaleType: 'time', data: chart.xAxis }]}
        series={[{ data: chart.data }]}
        width={450}
        height={200}
      />
    );
  }
}

function renderButtons(handleButtonClick, currentDataType) {
  const buttonLabels = ['Shift', 'Day', 'Week', 'Month', 'Quarter', 'Year'];
  return buttonLabels.map((label, index) => (
    <Button
      key={index}
      variant="contained"
      style={{ margin: '0px 2px', backgroundColor: currentDataType === label.toLowerCase() ? 'rgba(3, 3, 62, 0.9)' : 'gray' }}
      onClick={() => handleButtonClick(label.toLowerCase())}
    >
      {label}
    </Button>
  ));
}
