// import React, { useEffect, useState } from "react";

// import {
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   Box,
//   TextField,
//   Button,
//   Grid,
//   tableCellClasses,
//   styled,
//   TablePagination,
//   CircularProgress,
//   Typography,
//   CardContent,
//   Card,
// } from "@mui/material";
// import {
//   Table,
//   TableHead,
//   TableBody,
//   TableRow,
//   TableCell,
// } from "@mui/material";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { apigetMachine } from "../api/apigetmachine";
// import { apigetLines } from "../api/api.getline";
// import { apigetRawData } from "../api/api.getrawdata";
// import { format } from "date-fns";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { parseISO } from "date-fns";
// import { useAuthCheck } from "../utils/Auth";
// import DownloadButton from "../utils/DownloadButton";
// import "../assets/css/forms.css";
// const getCurrentDate = () => {
//   const today = new Date();
//   const yyyy = today.getFullYear();
//   const mm = String(today.getMonth() + 1).padStart(2, "0");
//   const dd = String(today.getDate()).padStart(2, "0");
//   return `${yyyy}-${mm}-${dd}`;
// };

// const MachineCard = ({ machine }) => {
//   const getBackgroundColor = (value) => {
//     if (value >= 75) return "green";
//     if (value >= 50) return "yellow";
//     return "red";
//   };

//   return (
//     <Card>
//       <CardContent>
//         <Typography variant="h5">{machine.name}</Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={6}>
//             <Typography
//               variant="body2"
//               style={{ backgroundColor: getBackgroundColor(machine.AE) }}
//             >
//               AE: {machine.AE}
//             </Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography
//               variant="body2"
//               style={{ backgroundColor: getBackgroundColor(machine.PE) }}
//             >
//               PE: {machine.PE}
//             </Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography
//               variant="body2"
//               style={{ backgroundColor: getBackgroundColor(machine.QE) }}
//             >
//               QE: {machine.QE}
//             </Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography
//               variant="body2"
//               style={{ backgroundColor: getBackgroundColor(machine.OEE) }}
//             >
//               OEE: {machine.OEE}
//             </Typography>
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="body2">
//               Part Count: {machine.partCount}
//             </Typography>
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="body2">
//               Component ID: {machine.componentId}
//             </Typography>
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="body2">
//               Down Time: {machine.downTime}
//             </Typography>
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="body2">
//               Utilized Time: {machine.utilizedTime}
//             </Typography>
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="body2">
//               Operator Name: {machine.operatorName}
//             </Typography>
//           </Grid>
//         </Grid>
//       </CardContent>
//     </Card>
//   );
// };

// export default function IconicDashboard() {
//   const [lineData, setLineData] = useState([]);
//   const [refreshData, setRefreshData] = useState(false);
//   const [error, setError] = useState(null);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [severity, setSeverity] = useState("success");
//   const [machineData, setMachineData] = useState([]);
//   const [selectedLine, setSelectedLine] = useState("");

//   const [rawData, setRawData] = useState({
//     lineNo: "",
//     machineId: "",
//     fromDate: getCurrentDate(),
//     toDate: getCurrentDate(),
//   });

//   useAuthCheck();
//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };
//   useEffect(() => {
//     const getmachine = async () => {
//       try {
//         const result = await apigetMachine();
//         console.log("Result data machine:", result.data.data);
//         setMachineData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getmachine();
//   }, [refreshData]);

//   useEffect(() => {
//     const getLine = async () => {
//       try {
//         const result = await apigetLines();
//         console.log("Result data line:", result.data.data);
//         setLineData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getLine();
//   }, [refreshData]);
//   const handleInputChange = (e) => {
//     console.log(e.target.name, e.target.value);
//     const { name, value } = e.target;
//     setRawData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//     if (name === "lineNo") {
//       setSelectedLine(value);
//     }
//   };
//   return (
//     <div style={{ padding: "0px 20px", width: "100%" }}>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "flex-start",
//           margin: "20px 0px 20px 0px",
//         }}
//       >
//         <h2> </h2>
//       </div>
//       <Grid
//         container
//         spacing={2}
//         style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
//       >
//         <Grid item xs={6} sm={3}>
//           {" "}
//           <FormControl sx={{ minWidth: 250 }}>
//             <InputLabel>Select Plant</InputLabel>
//             <Select
//               name="lineNo"
//               //   value={rawData?.lineNo}
//               //   onChange={handleInputChange}
//             >
//               {lineData.map((line) => (
//                 <MenuItem key={line.id} value={line.lineNo}>
//                   {line.lineName}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           {" "}
//           <FormControl sx={{ minWidth: 250 }}>
//             <TextField
//               label="Start Date"
//               name="fromDate"
//               type="date"
//               placeholder="Start Date"
//               value={rawData?.fromDate}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           {" "}
//           <FormControl sx={{ minWidth: 250 }}>
//             <TextField
//               label="End Date"
//               name="toDate"
//               type="date"
//               value={rawData?.toDate}
//               onChange={handleInputChange}
//             />
//           </FormControl>
//         </Grid>
//         <Grid item>
//           {" "}
//           <Button variant="contained" color="primary">
//             OK
//           </Button>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// import React, { useEffect, useState } from "react";
// import {
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   TextField,
//   Button,
//   Grid,
//   Typography,
//   CardContent,
//   Card,
//   Snackbar,
//   Alert as MuiAlert,
// } from "@mui/material";
// import { apigetMachine } from "../api/apigetmachine";
// import { apigetLines } from "../api/api.getline";
// import { useAuthCheck } from "../utils/Auth";
// import "../assets/css/forms.css";
// import { apiMachinePerformance } from "../api/api.MachinePerformance";

// const getCurrentDateTime = () => {
//   const now = new Date();
//   const yyyy = now.getFullYear();
//   const mm = String(now.getMonth() + 1).padStart(2, "0");
//   const dd = String(now.getDate()).padStart(2, "0");
//   const hh = String(now.getHours()).padStart(2, "0");
//   const min = String(now.getMinutes()).padStart(2, "0");
//   return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
// };

// const MachineCard = ({ machine, performanceData }) => {
//   const getBackgroundColor = (value, type) => {
//     if (type === "percentage") {
//       value = parseFloat(value);
//       if (value >= 75) return "green";
//       if (value >= 50) return "yellow";
//       return "red";
//     } else {
//       return value.toLowerCase();
//     }
//   };

//   const machinePerformance = performanceData.find(
//     (p) => p.machineId === machine.id
//   );

//   return (
//     <Card>
//       <CardContent>
//         <Typography variant="h5">{machine.displayMachineName}</Typography>
//         <Grid container spacing={2}>
//           {machinePerformance ? (
//             <>
//               <Grid item xs={6}>
//                 <Typography
//                   variant="body2"
//                   style={{
//                     backgroundColor: getBackgroundColor(
//                       machinePerformance.availabilityPercentage,
//                       "percentage"
//                     ),
//                   }}
//                 >
//                   Availability: {machinePerformance.availabilityPercentage}
//                 </Typography>
//               </Grid>
//               <Grid item xs={6}>
//                 <Typography
//                   variant="body2"
//                   style={{
//                     backgroundColor: getBackgroundColor(
//                       machinePerformance.performancePercentage,
//                       "percentage"
//                     ),
//                   }}
//                 >
//                   Performance: {machinePerformance.performancePercentage}
//                 </Typography>
//               </Grid>
//               <Grid item xs={6}>
//                 <Typography
//                   variant="body2"
//                   style={{
//                     backgroundColor: getBackgroundColor(
//                       machinePerformance.qualityPercentage,
//                       "percentage"
//                     ),
//                   }}
//                 >
//                   Quality: {machinePerformance.qualityPercentage}
//                 </Typography>
//               </Grid>
//               <Grid item xs={6}>
//                 <Typography
//                   variant="body2"
//                   style={{
//                     backgroundColor: getBackgroundColor(
//                       machinePerformance.oeeStatus,
//                       "status"
//                     ),
//                   }}
//                 >
//                   OEE: {machinePerformance.oee}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12}>
//                 <Typography variant="body2">
//                   Total Time: {machinePerformance.totalTime}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12}>
//                 <Typography variant="body2">
//                   Utilized Time: {machinePerformance.utilizedTime}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12}>
//                 <Typography variant="body2">
//                   Down Time: {machinePerformance.downTime}
//                 </Typography>
//               </Grid>
//             </>
//           ) : (
//             <Grid item xs={12}>
//               <Typography variant="body2">
//                 No performance data available
//               </Typography>
//             </Grid>
//           )}
//         </Grid>
//       </CardContent>
//     </Card>
//   );
// };

// export default function IconicDashboard() {
//   const [lineData, setLineData] = useState([]);
//   const [refreshData, setRefreshData] = useState(false);
//   const [error, setError] = useState(null);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [severity, setSeverity] = useState("success");
//   const [machineData, setMachineData] = useState([]);
//   const [selectedLine, setSelectedLine] = useState("");
//   const [performanceData, setPerformanceData] = useState([]);

//   const [rawData, setRawData] = useState({
//     lineNo: "",
//     machineId: "",
//     fromDate: getCurrentDateTime(),
//     toDate: getCurrentDateTime(),
//   });
//   useAuthCheck();

//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };

//   useEffect(() => {
//     const getLine = async () => {
//       try {
//         const result = await apigetLines();
//         console.log("Result data line:", result.data.data);
//         setLineData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getLine();
//   }, [refreshData]);

//   useEffect(() => {
//     if (selectedLine) {
//       const getmachine = async () => {
//         try {
//           const result = await apigetMachine(selectedLine);
//           console.log("Result data machine:", result.data.data);

//           const machinesWithDummyValues = result.data.data.map((machine) => ({
//             ...machine,
//             AE: 50,
//             PE: 45,
//             QE: 65,
//             OEE: 75,
//             partCount: 110,
//             componentId: "C789",
//             downTime: "3 hours",
//             utilizedTime: "5 hours",
//             operatorName: "Jim Brown",
//           }));
//           setMachineData(machinesWithDummyValues);
//         } catch (error) {
//           setError(error.message);
//           handleSnackbarOpen(error.message, "error");
//         }
//       };
//       getmachine();
//     }
//   }, [selectedLine]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setRawData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//     if (name === "lineNo") {
//       setSelectedLine(value);
//     }
//   };
//   const handleOkClick = async () => {
//     try {
//       const result = await apiMachinePerformance({
//         lineNo: rawData.lineNo,
//         fromDate: rawData.fromDate,
//         toDate: rawData.toDate,
//       });
//       console.log("machine performance data:", result.data);
//       setPerformanceData(result.data);
//       handleSnackbarOpen("Data fetched successfully", "success");
//     } catch (error) {
//       console.error("Error in handleOkClick:", error);
//       setError(error.message);
//       handleSnackbarOpen(error.message, "error");
//     }
//   };
//   return (
//     <div style={{ padding: "0px 20px", width: "100%" }}>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "flex-start",
//           margin: "20px 0px 20px 0px",
//         }}
//       >
//         <h2>Machine Dashboard</h2>
//       </div>
//       <Grid
//         container
//         spacing={2}
//         style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
//       >
//         <Grid item xs={6} sm={3}>
//           <FormControl sx={{ minWidth: 250 }}>
//             <InputLabel>Select Plant</InputLabel>
//             <Select
//               name="lineNo"
//               value={rawData?.lineNo}
//               onChange={handleInputChange}
//             >
//               {lineData.map((line) => (
//                 <MenuItem key={line.id} value={line.lineNo}>
//                   {line.lineName}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           <FormControl sx={{ minWidth: 250 }}>
//             <TextField
//               label="Start Date & Time"
//               name="fromDate"
//               type="datetime-local"
//               value={rawData?.fromDate}
//               onChange={handleInputChange}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//             />
//           </FormControl>
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           <FormControl sx={{ minWidth: 250 }}>
//             <TextField
//               label="End Date & Time"
//               name="toDate"
//               type="datetime-local"
//               value={rawData?.toDate}
//               onChange={handleInputChange}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//             />
//           </FormControl>
//         </Grid>
//         <Grid item>
//           <Button variant="contained" color="primary" onClick={handleOkClick}>
//             OK
//           </Button>
//         </Grid>
//       </Grid>
//       <Grid container spacing={2}>
//         {machineData.length === 0 ? (
//           <Typography variant="h6">
//             No machines found for the selected plant.
//           </Typography>
//         ) : (
//           machineData.map((machine) => (
//             <Grid item xs={12} sm={6} md={4} key={machine.id}>
//               <MachineCard
//                 machine={machine}
//                 performanceData={performanceData}
//               />
//             </Grid>
//           ))
//         )}
//       </Grid>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={() => setOpenSnackbar(false)}
//       >
//         <MuiAlert
//           elevation={6}
//           variant="filled"
//           onClose={() => setOpenSnackbar(false)}
//           severity={severity}
//         >
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Grid,
  Typography,
  CardContent,
  Card,
  Snackbar,
  Alert as MuiAlert,
  LinearProgress,
} from "@mui/material";
import { apigetMachine } from "../api/apigetmachine";
import { apigetLines } from "../api/api.getline";
import { apiMachinePerformance } from "../api/api.MachinePerformance";
import { useAuthCheck } from "../utils/Auth";
import "../assets/css/forms.css";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
const getCurrentDateTime = () => {
  const now = new Date();
  return now.toISOString().slice(0, 16);
};

const getBackgroundColor = (value, type) => {
  if (type === "percentage") {
    value = parseFloat(value);
    if (value >= 75) return "darkgreen";
    if (value >= 50) return "yellow";
    return "red";
  } else {
    return value.toLowerCase();
  }
};

const StyledCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(45deg, #ededed 30%, #ffffff 90%)",
  // backdropFilter: 'blur(10px)',
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.03)",
  },
}));

const MetricBox = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.2)",
  borderRadius: "10px",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const getColor = (value) => {
  value = parseFloat(value);
  if (value >= 75) return "#4caf50";
  if (value >= 50) return "#ff9800";
  return "#f44336";
};

const MachineCard = ({ machine }) => {
  return (
    <StyledCard>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: "bold", color: "#1a237e" }}
        >
          {machine.machineName}
        </Typography>
        <Grid container spacing={2}>
          {[
            "Availability",
            "Performance",
            "Quality",
            "OEE",
            "OPE",
            "Utilization",
          ].map((metric) => (
            <Grid item xs={4} key={metric}>
              <MetricBox
                style={{
                  backgroundColor: getColor(
                    machine[`${metric.toLowerCase()}Percentage`]
                  ),
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "18px", fontWeight: "500" }}
                >
                  {metric}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    backgroundColor: getColor(
                      machine[`${metric.toLowerCase()}Percentage`]
                    ),
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {machine[`${metric.toLowerCase()}Percentage`]}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={parseFloat(
                    machine[`${metric.toLowerCase()}Percentage`]
                  )}
                  style={{
                    height: 5,
                    borderRadius: 5,
                    backgroundColor: "rgba(0,0,0,0.1)",
                    color: getColor(
                      machine[`${metric.toLowerCase()}Percentage`]
                    ),
                  }}
                />
              </MetricBox>
            </Grid>
          ))}
        </Grid>
        <Box
          mt={2}
          sx={{
            border: "1px solid black",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
          <Typography variant="body2" sx={{ fontSize: "18px" }}>
            Total Time: {machine.totalTime}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "18px" }}>
            Utilized Time: {machine.utilizedTime}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "18px" }}>
            Down Time: {machine.downTime}
          </Typography>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default function IconicDashboard() {
  const [lineData, setLineData] = useState([]);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [machineData, setMachineData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [showCards, setShowCards] = useState(false);

  const [rawData, setRawData] = useState({
    lineNo: "",
    fromDate: getCurrentDateTime(),
    toDate: getCurrentDateTime(),
  });

  useAuthCheck();

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    const getLine = async () => {
      try {
        const result = await apigetLines();
        setLineData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getLine();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRawData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${date.getDate().toString().padStart(2, "0")}-${
      months[date.getMonth()]
    }-${date.getFullYear()} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:00`;
  };

  const handleOkClick = async () => {
    try {
      const performanceResult = await apiMachinePerformance({
        lineNo: rawData.lineNo,
        fromDate: formatDate(rawData.fromDate),
        toDate: formatDate(rawData.toDate),
      });
      console.log("machine performance data:", performanceResult.data);
      setMachineData(performanceResult.data);
      setShowCards(true);
      handleSnackbarOpen("Data fetched successfully", "success");
    } catch (error) {
      console.error("Error in handleOkClick:", error);
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
      setShowCards(false);
    }
  };

  return (
    <div style={{ padding: "0px 20px", width: "100%", marginTop: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "20px 0px 20px 0px",
        }}
      >
        <h2>Machine Performance </h2>
      </div>
      <Grid
        container
        spacing={2}
        style={{
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "flex-start",
        }}
      >
        <Grid item xs={3} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Plant</InputLabel>
            <Select
              name="lineNo"
              value={rawData.lineNo}
              onChange={handleInputChange}
            >
              {lineData.map((line) => (
                <MenuItem key={line.id} value={line.lineNo}>
                  {line.lineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="Start Date & Time"
              name="fromDate"
              type="datetime-local"
              value={rawData.fromDate}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="End Date & Time"
              name="toDate"
              type="datetime-local"
              value={rawData.toDate}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleOkClick}>
            OK
          </Button>
        </Grid>
      </Grid>

      {showCards && (
        <Grid container spacing={2}>
          {machineData.length === 0 ? (
            <Typography variant="h6">
              No machines found for the selected plant.
            </Typography>
          ) : (
            machineData.map((machine) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={machine.machineId}
                style={{ marginBottom: "40px" }}
              >
                <MachineCard machine={machine} />
              </Grid>
            ))
          )}
        </Grid>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
