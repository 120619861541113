import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import '../assets/css/emanagement.css'
import BackButton from './backbutton';
import { useAuthCheck } from '../utils/Auth';

const chartData = [
//   { type: 'line', xAxis: [1, 2, 3, 5, 8, 10], data: [2, 5.5, 2, 8.5, 1.5, 5] },
//   { type: 'line', xAxis: [1, 2, 3, 4, 5, 6], data: [3, 4, 6, 8, 2, 7] },
//   { type: 'line', xAxis: [1, 2, 3, 4, 5, 6], data: [2, 4, 6, 3, 5, 8] },
  { type: 'bar', xAxis: ['group A', 'group B', 'group C'], data: [7, 3, 5] },

  { type: 'bar', xAxis: ['group A', 'group B', 'group C'], data: [6, 2, 9] },
  { type: 'bar', xAxis: ['group A', 'group B', 'group C'], data: [4, 7, 8] },


];

export default function STPManagement() {
  useAuthCheck();
  return (
    <div style={{  padding:'20px' }}>
       <Grid container spacing={2} alignItems="center">
      <Grid item xs={5} display={'flex'}>
      <BackButton/>
          <Typography variant="h5" gutterBottom>
         STP Management
          </Typography>
        </Grid>
        <Grid item xs={7} container justifyContent="flex-end">
          {/* Render Buttons */}
          {renderButtons()}
        </Grid>

        {chartData.map((chart, index) => (
          <Grid item xs={4} key={index}>
            <Paper elevation={3} style={{ padding: 20, backgroundColor: 'rgba(3, 3, 62, 0.9)' }}>
              <Typography variant="h6" gutterBottom style={getChartTitleStyle(chart, index)}>
                {getChartTitle(chart, index)}
              </Typography>
              {renderChart(chart)}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function getChartTitle(chart, index) {
  if (chart.type === 'line') {
    switch (index) {
    //   case 0:
    //     return 'Temperature (°C)';
    //   case 1:
    //     return 'Humidity (%)';
    //   case 2:
    //     return 'CO2 (PPM)';
    //   case 4:
    //     return 'CO2 (PPM)';
    //   case 5:
    //     return 'kVAR';
      default:
        return `Line Chart ${index + 1}`;
    }
  } else if (chart.type === 'bar') {
    switch (index) {
      case 0:
        return 'Totalized_Flow';
        case 1:
            return 'Batch1_Current_Value';
            case 2:
                return 'Batch2_Current_Value';

      default:
        return `Bar Chart ${index + 1}`;
    }
  } else {
    return `Chart ${index + 1}`;
  }
}

function getChartTitleStyle(chart, index) {
  const defaultStyle = {
    color: 'white', 
    fontFamily: 'Arial, sans-serif', 
  
  };

 
  if (chart.type === 'line') {
    switch (index) {
      case 0:
        return { ...defaultStyle, fontWeight: 'bold' }; 
      case 1:
        return { ...defaultStyle, fontWeight: 'bold'  }; 
        case 3:
          return { ...defaultStyle, fontWeight: 'bold' }; 
          case 4:
            return { ...defaultStyle, fontWeight: 'bold'  }; 
            case 5:
              return { ...defaultStyle, fontWeight: 'bold'  }; 
                  
      default:
        return defaultStyle;
    }
  } else if (chart.type === 'bar') {
    switch (index) {
      case 2:
        return { ...defaultStyle, fontWeight: 'bold'  };
      default:
        return defaultStyle;
    }
  } else {
    return defaultStyle;
  }
}


function renderChart(chart) {
  if (chart.type === 'bar') {
    return (
      <BarChart
        xAxis={[{ scaleType: 'band', data: chart.xAxis }]}
        series={[{ data: chart.data }]}
        width={450}
        height={200}
      />
    );
  } else {
    return (
      <LineChart
        xAxis={[{ data: chart.xAxis }]}
        series={[{ data: chart.data }]}
        width={450}
        height={200}
      />
    );
  }
}


function renderButtons() {
  const buttonLabels = ['Shift', 'Day', 'Week', 'Month', 'Quarter', 'Year'];

  return buttonLabels.map((label, index) => (
    <Button key={index} variant="contained" style={{ margin: '0 5px' }}>
      {label}
    </Button>
  ));
}