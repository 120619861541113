import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Grid,
  Snackbar,
  Alert as MuiAlert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,styled,tableCellClasses
} from "@mui/material";
import { apigetLines } from "../api/api.getline";
import { apiMachinePerformance } from "../api/api.MachinePerformance";
import { useAuthCheck } from "../utils/Auth";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1FAEC5",
      color: theme.palette.common.white,
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

const getCurrentDateTime = () => {
  const now = new Date();
  return now.toISOString().slice(0, 16);
};

export default function TabularViewMP() {
  const [lineData, setLineData] = useState([]);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [machineData, setMachineData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [rawData, setRawData] = useState({
    lineNo: "",
    fromDate: getCurrentDateTime(),
    toDate: getCurrentDateTime(),
  });

  useAuthCheck();

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    const getLine = async () => {
      try {
        const result = await apigetLines();
        setLineData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getLine();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRawData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${date.getDate().toString().padStart(2, "0")}-${
      months[date.getMonth()]
    }-${date.getFullYear()} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:00`;
  };

  const handleOkClick = async () => {
    try {
      const performanceResult = await apiMachinePerformance({
        lineNo: rawData.lineNo,
        fromDate: formatDate(rawData.fromDate),
        toDate: formatDate(rawData.toDate),
      });
      setMachineData(performanceResult.data);
      setShowTable(true);
      handleSnackbarOpen("Data fetched successfully", "success");
    } catch (error) {
      console.error("Error in handleOkClick:", error);
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
      setShowTable(false);
    }
  };

  const getBackgroundColor = (status) => {
    switch (status.toLowerCase()) {
      case "green":
        return "#19b919";
      case "yellow":
        return "#ebeb0f";
      case "red":
        return "red";
      default:
        return "transparent";
    }
  };

  return (
    <div style={{ padding: "0px 20px", width: "100%", marginTop: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "20px 0px 20px 0px",
        }}
      >
        <h2>Machine Performance </h2>
        </div>
        <Grid
          container
          spacing={2}
          style={{ width: "100%", alignItems: "center", marginBottom: "10px", justifyContent: "" }}
        >
          <Grid item xs={3} sm={3}>
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel>Select Plant</InputLabel>
              <Select
                name="lineNo"
                value={rawData.lineNo}
                onChange={handleInputChange}
              >
                {lineData.map((line) => (
                  <MenuItem key={line.id} value={line.lineNo}>
                    {line.lineName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} sm={3}>
            <FormControl sx={{ minWidth: 250 }}>
              <TextField
                label="Start Date & Time"
                name="fromDate"
                type="datetime-local"
                value={rawData.fromDate}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3} sm={3}>
            <FormControl sx={{ minWidth: 250 }}>
              <TextField
                label="End Date & Time"
                name="toDate"
                type="datetime-local"
                value={rawData.toDate}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleOkClick}>
              OK
            </Button>
          </Grid>
        </Grid>
    

      {showTable && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Machine Name</StyledTableCell>
                <StyledTableCell>Availability</StyledTableCell>
                <StyledTableCell>OEE</StyledTableCell>
                <StyledTableCell>OPE</StyledTableCell>
                <StyledTableCell>Machine Wise Performance</StyledTableCell>
                <StyledTableCell>Quality</StyledTableCell>
                <StyledTableCell>Utilization</StyledTableCell>
                <StyledTableCell>Down Time</StyledTableCell>
                <StyledTableCell>Utilized Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {machineData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.machineName}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: getBackgroundColor(row.availabilityStatus) }}>
                    {row.availabilityPercentage}
                  </StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: getBackgroundColor(row.oeeStatus) }}>
                    {row.oeePercentage}
                  </StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: getBackgroundColor(row.opeStatus) }}>
                    {row.opePercentage}
                  </StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: getBackgroundColor(row.performanceStatus) }}>
                    {row.performancePercentage}
                  </StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: getBackgroundColor(row.qualityStatus) }}>
                    {row.qualityPercentage}
                  </StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: getBackgroundColor(row.utilizationStatus) }}>
                    {row.utilizationPercentage}
                  </StyledTableCell>
                  <StyledTableCell>{row.downTime}</StyledTableCell>
                  <StyledTableCell>{row.utilizedTime}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
