// import axios from "axios";
// import { baseUrl } from "./baseUrl";

// export const apiGetOpe2= async (lineId,machineId) => {
//   const url = `${baseUrl}/common/getOpeM2Data/${lineId}/${machineId}`;
  
//   try {
//     const token=localStorage.getItem("token")
   
//     const data = await axios.get(url, {headers:{
//         Authorization:`Bearer ${token}`
//     }});
//     return data;
//   } catch (error) {
//     console.error("Error during getting part:", error);
//     throw error;
// }
// };



import axios from "axios";
import { baseUrl } from "./baseUrl";

export const apiGetOpe2 = async ({ lineId, machineId, fromDate, toDate }) => {
  const url = baseUrl + "/common/getOpeM2DataByDate";
  try {
    const token = localStorage.getItem("token");
    
    const data = await axios.post(
      url,
      {
        "lineNo":lineId,
        "machineId":machineId,
        "fromDate":fromDate,
        "toDate":toDate
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("API response data:", data.data.data);
    return data.data; 
  } catch (error) {
    console.error("Error during getting raw data:", error);
    throw error;
  }
};
