import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  tableCellClasses,
  styled,
  TablePagination,
  Snackbar,
  CircularProgress,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { apiGetOpe2 } from "../api/api.getope2";
import { apigetMachine } from "../api/apigetmachine";
import MuiAlert from "@mui/material/Alert";
import { useAuthCheck } from "../utils/Auth";
import DownloadButton from "../utils/DownloadButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(today.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

export default function PathrediOpe2() {
  const [ope2Data, setOpe2Data] = useState([]);
  const [machineId, setMachineId] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [lineId, setLineId] = useState("18");
  const [machineData, setMachineData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [lineNo, setLineNo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ghaziabadOpe2Data, setGhaziabadOpe2Data] = useState({
    lineNo: "",
    machineId: '',
    fromDate: getCurrentDate(),
    toDate: getCurrentDate(),
  });


  useAuthCheck();
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("Result data machine:", result.data.data);

        let lineNo = null;

        const filteredMachines = result.data.data.filter((machine) => {
          console.log("machine.lineNo:", machine.lineNo);
          if (machine.lineName === "Ghaziabad") {
            lineNo = machine.lineNo;
          }
          return machine.lineName === "Ghaziabad";
        });

        if (lineNo !== null) {
          setLineNo(lineNo);
        }

        console.log("Filtered machines:", filteredMachines);
        console.log("lineNo for Ghaziabad:", lineNo);

        setMachineData(filteredMachines);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getmachine();
  }, [refreshData]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setGhaziabadOpe2Data((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddSubmit = async () => {
    const { machineId, fromDate, toDate } = ghaziabadOpe2Data;
    const lineId = lineNo;
  
    setLoading(true);
    try {
      const result = await apiGetOpe2({ lineId, machineId, fromDate, toDate });
      setOpe2Data(result.data);
      handleSnackbarOpen("Ope Data fetched successfully!", "success");
    } catch (error) {
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
    } finally {
      setLoading(false);
    }
  };
  


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const downloadApiCall = async () => {
    const { machineId, fromDate, toDate } = ghaziabadOpe2Data;
    const lineId = lineNo;

    if (lineId && machineId) {
      try {
        const data = await apiGetOpe2({lineId, machineId, fromDate, toDate});
        return data.data;
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      "M Id": row.mid ?? "",
      "Date Time": row.dateTimeRecvd ?? "",
      "Cycletime ": row.cycleTime != null ? parseFloat(row.cycleTime) : "",
      "VAT": row.vatM2 != null ? parseFloat(row.vatM2) : "",
      "AD Data": row.adData ?? "",
      "AD Diff Data": row.adDiffData ?? "",
      "DCT%": row.dctPercent != null ? parseFloat(row.dctPercent) : "",
      "Loss Type For Ope": row.lossTypeForOpe ?? "",
      "P Loss": row.pLossM2 != null ? parseFloat(row.pLossM2) : "",
      "A Loss": row.aLossM2 != null ? parseFloat(row.aLossM2) : "",
      "M2 Part Name": row.partNameM2 ?? "",
      "Category": row.category ?? "",
    }));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, ope2Data.length - page * rowsPerPage);
  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingTop: "5px",
          paddingBottom: "15px",
        }}
      >
        <h2> Ghaziabad Ope 2</h2>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControl
          sx={{ width: "30ch", margonTop: "20px", }}
        >
          <InputLabel>Select Machine</InputLabel>
          <Select
            name="machineId"
            value={ghaziabadOpe2Data.machineId}
            onChange={handleInputChange}
          >
            {machineData.map((id) => (
              <MenuItem key={id.id} value={id.machineId}>
                {id.displayMachineName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 ,marginLeft:2}}>
            <TextField
              label="Start Date"
              name="fromDate"
              type="date"
              value={ghaziabadOpe2Data.fromDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
        <FormControl sx={{ minWidth: 250 ,marginLeft:2}}>

            <TextField
              label="End Date"
              name="toDate"
              type="date"
              value={ghaziabadOpe2Data.toDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleAddSubmit} sx={{marginLeft:2,bgcolor:'#4895a1',color:'black'}}>
            OK
          </Button>
        </Grid>

        <div style={{ marginLeft: "30px" }}>
          <DownloadButton
            apiCall={downloadApiCall}
            formatData={formatData}
            fileName="Ghaziabad Ope 2.xlsx"
          />
        </div>
      </div>
      <Box sx={{ maxHeight: "500px", overflow: "auto" ,marginTop:'20px'}}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead style={{ backgroundColor: "#0002" }}>
              <TableRow width="100%">
                <StyledTableCell className="table-cell">MID</StyledTableCell>
                <StyledTableCell className="table-cell">
                  Date Time
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Cycle Time
                </StyledTableCell>
                <StyledTableCell className="table-cell">VAT</StyledTableCell>
                <StyledTableCell className="table-cell">
                  AD Data
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  AD Diff Data
                </StyledTableCell>
                <StyledTableCell className="table-cell">DCT% </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Loss type for OPE
                </StyledTableCell>

                {/* <StyledTableCell className="table-cell">U Loss</StyledTableCell> */}
                <StyledTableCell className="table-cell">P Loss</StyledTableCell>
                <StyledTableCell className="table-cell">A Loss</StyledTableCell>
                <StyledTableCell className="table-cell">
                  M2 Part Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Category
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ope2Data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className="table-cell">
                      {row.mid}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {" "}
                      {row.dateTimeRecvd}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.cycleTime}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {" "}
                      {row.vatM2}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.adData}{" "}
                    </StyledTableCell>

                    <StyledTableCell className="table-cell">
                      {row.adDiffData}{" "}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.dctPercent}{" "}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.lossTypeForOpe}
                    </StyledTableCell>
                    {/* <StyledTableCell className="table-cell">{row.vatM2}</StyledTableCell> */}
                    {/* <StyledTableCell className="table-cell">{row.dateTime}</StyledTableCell> */}
                    <StyledTableCell className="table-cell">
                      {row.pLossM2}{" "}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {" "}
                      {row.aLossM2}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {" "}
                      {row.partNameM2}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {" "}
                      {row.category}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 1000]}
          component="div"
          count={ope2Data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
