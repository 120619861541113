import React from 'react';
import { Select, MenuItem, InputLabel, FormControl, Box, tableCellClasses, styled } from '@mui/material';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { useAuthCheck } from '../utils/Auth';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function UATRawData() {
  // Dummy data for table rows
  const dummyData = [
 
    { id: 8921, dateTime: '2024-03-20 10:00:00', cycleTime: '234.34' },
    { id: 2922, dateTime: '2024-03-20 11:30:00', cycleTime: '1384.434' },
    { id: 4123, dateTime: '2024-03-20 13:00:00', cycleTime: '23.32' },
    // Add more dummy data as needed
  ];
useAuthCheck();
  return (
    <div style={{padding:'0px 20px'}}>
        <div style={{display:'flex',justifyContent:'flex-start',paddingTop: "5px", paddingBottom: "5px"}}>
          <h2>UAT Raw Data</h2>
        </div>
      <Box sx={{ overflow: 'scroll' }}>
      <Table size="small" style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }} >
          <TableHead>
            <TableRow style={{ backgroundColor: '#0002' }}>
              {/* Style for table header */}
              <StyledTableCell  className="table-cell" style={{fontSize: '16px' }} >Id</StyledTableCell >
              <StyledTableCell  className="table-cell" style={{fontSize: '16px' }} >Date Time</StyledTableCell >
              <StyledTableCell  className="table-cell" style={{fontSize: '16px' }} >Cycle Time</StyledTableCell >
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Mapping dummy data to table rows */}
            {dummyData.map((row) => (
              <StyledTableRow key={row.id}>
               <StyledTableCell  className="table-cell">{row.id}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.dateTime}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.cycleTime}</StyledTableCell >
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  );
}
