// import React, { useState } from "react";
// import {
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   TextField,
//   Grid,
//   Button,
// } from "@mui/material";

// import { Bar } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const ProductionGraphChart = () => {
//   const [selectedPlant, setSelectedPlant] = useState("");
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [productionData, setProductionData] = useState([]);

//   // Mock data - replace this with actual data fetching logic
//   const fetchProductionData = () => {
//     // Simulating API call
//     const mockData = [
//         { machine: 'Machine 1', '8-9': 0, '9-10': 80, '10-11': 60, '11-12': 15, '12-13': 90, '13-14': 70, '14-15': 85, '15-16': 40 },
//         { machine: 'Machine 2', '8-9': 70, '9-10': 90, '10-11': 0, '11-12': 85, '12-13': 75, '13-14': 0, '14-15': 80, '15-16': 95 },
//         { machine: 'Machine 3', '8-9': 50, '9-10': 75, '10-11': 80, '11-12': 0, '12-13': 85, '13-14': 90, '14-15': 0, '15-16': 70 },
//         { machine: 'Machine 4', '8-9': 85, '9-10': 0, '10-11': 70, '11-12': 90, '12-13': 0, '13-14': 85, '14-15': 75, '15-16': 80 },
//         { machine: 'Machine 5', '8-9': 60, '9-10': 65, '10-11': 75, '11-12': 80, '12-13': 85, '13-14': 0, '14-15': 90, '15-16': 0 },
//       ];
//       setProductionData(mockData);
//     };

//   const handlePlantChange = (event) => {
//     setSelectedPlant(event.target.value);
//   };

 
//   const getBarColor = (production) => {
//     if (production === 0) return 'red';
//     if (production > 75) return 'green';
//     if (production < 20) return 'yellow';
//     return 'blue'; // Default color
//   };

//   const timeSlots = productionData.length > 0 ? Object.keys(productionData[0]).filter(key => key !== 'machine') : [];

//   const chartData = {
//     labels: timeSlots,
//     datasets: productionData.map(data => ({
//       label: data.machine,
//       data: timeSlots.map(timeSlot => data[timeSlot]),
//       backgroundColor: timeSlots.map(timeSlot => getBarColor(data[timeSlot])),
//     })),
//   };

//   const chartOptions = {
//     responsive: true,
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: 'Time',
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: 'Production',
//         },
//         beginAtZero: true,
//       },
//     },
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       title: {
//         display: true,
//         text: 'Production Graph Chart',
//       },
//     },
//   };
//   return (
//     <div style={{ padding: "0px 20px", width: "100%" }}>
//          <div
//         style={{
//           display: "flex",
//           justifyContent: "flex-start",
//           margin: "10px 0px 10px 0px",
//         }}
//       >
//         <h2>Production Graph </h2>
//       </div>
//       <Grid
//         container
//         spacing={2}
//         style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
//       >
//            <Grid item xs={6} sm={3}>
//         <FormControl sx={{ minWidth: 250 }}>
//           <InputLabel id="plant-select-label">Plant</InputLabel>
//           <Select
//             labelId="plant-select-label"
//             id="plant-select"
//             value={selectedPlant}
//             label="Plant"
//             onChange={handlePlantChange}
//           >
//             <MenuItem value="plant1">Plant 1</MenuItem>
//             <MenuItem value="plant2">Plant 2</MenuItem>
//             <MenuItem value="plant3">Plant 3</MenuItem>
//           </Select>
//         </FormControl>
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           <FormControl sx={{ minWidth: 250 }}>
//             <TextField
//               label="Start Date"
//               name="fromDate"
//               type="date"
//               placeholder="Start Date"
//               //   value={rawData?.fromDate}
//               //   onChange={handleInputChange}
//             />
//           </FormControl>
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           <FormControl sx={{ minWidth: 250 }}>
//             <TextField
//               label="End Date"
//               name="toDate"
//               type="date"
//               //   value={rawData?.toDate}
//               //   onChange={handleInputChange}
//             />
//           </FormControl>
//         </Grid>
//         <Grid item>
//         <Button variant="contained" color="primary" onClick={fetchProductionData}>
//         Fetch Data
//       </Button>
//       </Grid>
//       </Grid>

     
//       {productionData.length > 0 && (
//         <div style={{ marginTop: '20px', height: '400px' }}>
//           <Bar options={chartOptions} data={chartData} />
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProductionGraphChart;

import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import '../assets/css/ProductionGraphChart.css';
import dayjs from "dayjs";

const ProductionGraphChart = ({ data, startTime, endTime }) => {
  const totalMinutes = (endTime - startTime) / (60 * 1000);
  const intervals = Math.ceil(totalMinutes / 30);
  const defaultDate = dayjs().format('YYYY-MM-DD');

  const generateTimeLabels = () => {
    const labels = [];
    for (let i = 0; i <= intervals; i++) {
      const time = new Date(startTime.getTime() + i * 30 * 60000);
      labels.push(time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    }
    return labels;
  };

  const timeLabels = generateTimeLabels();

  return (
    <div style={{ padding: "20px", width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "flex-start", margin: "10px 0" }}>
        <h2>Production Graph</h2>
      </div>
      <Grid
        container
        spacing={2}
        style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="plant-select-label">Plant</InputLabel>
            <Select
              labelId="plant-select-label"
              id="plant-select"
              // value={selectedPlant}
              label="Plant"
              // onChange={handlePlantChange}
            >
              <MenuItem value="plant1">Plant 1</MenuItem>
              <MenuItem value="plant2">Plant 2</MenuItem>
              <MenuItem value="plant3">Plant 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="Start Date"
              name="fromDate"
              type="date"
              placeholder="Start Date"
              defaultValue={defaultDate} // Set default date here
              InputLabelProps={{
                shrink: true, // Ensure the label is always visible
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="End Date"
              name="toDate"
              type="date"
              defaultValue={defaultDate} // Set default date here
              InputLabelProps={{
                shrink: true, // Ensure the label is always visible
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary">
            OK
          </Button>
        </Grid>
      </Grid>
      <div className="chart-container" style={{ border: "1px solid #ccc", borderRadius: "4px", padding: "10px" }}>
        <div className="y-axis">
          {data.map(machine => (
            <div key={machine.name} className="machine-name">{machine.name}</div>
          ))}
        </div>
        <div className="chart-area">
          {data.map(machine => (
            <div key={machine.name} className="machine-row">
              {machine.activities.map((activity, index) => (
                <div
                  key={index}
                  className={`activity-bar ${activity.status}`}
                  style={{ width: `${activity.duration}%` }}
                />
              ))}
            </div>
          ))}
          <div className="time-axis">
            {timeLabels.map((label, index) => (
              <div key={index} className="time-label">{label}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionGraphChart;




// import React from 'react';
// import { ResponsiveContainer, XAxis, YAxis, Tooltip, Rectangle, ScatterChart, Scatter } from 'recharts';
// import moment from 'moment';

// const ProductionGraphChart = ({ data }) => {
//   // Process data for the chart
//   const chartData = data.flatMap((machine, machineIndex) =>
//     machine.data.map((value, timeIndex) => ({
//       machine: `Machine ${machineIndex + 1}`,
//       time: moment(machine.times[timeIndex]).format('HH:mm'),
//       value: value,
//     }))
//   );

//   // Custom shape for heatmap cells
//   const Cell = (props) => {
//     const { x, y, width, height, value } = props;
//     const fill = value === 0 ? 'red' : 'green';
//     return <Rectangle x={x} y={y} width={width} height={height} fill={fill} />;
//   };

//   return (
//     <ResponsiveContainer width="100%" height={400}>
//       <ScatterChart
//         margin={{
//           top: 20,
//           right: 20,
//           bottom: 20,
//           left: 60,
//         }}
//       >
//         <XAxis
//           dataKey="machine"
//           type="category"
//           allowDuplicatedCategory={false}
//         />
//         <YAxis
//           dataKey="time"
//           type="category"
//           allowDuplicatedCategory={false}
//           reversed
//         />
//         <Tooltip cursor={{ strokeDasharray: '10 10' }} />
//         <Scatter data={chartData} shape={<Cell />} height={20} width={20} />
//       </ScatterChart>
//     </ResponsiveContainer>
//   );
// };

// export default ProductionGraphChart;



// import React, { useEffect, useState } from 'react';
// import {
//   ScatterChart,
//   Scatter,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Cell,
//   ResponsiveContainer
// } from 'recharts';

// const HeatmapChart = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     // Dummy data representing machine production status
//     const dummyData = [
//       { time: '7:00', machine: 'machine1', status: 'green' },
      
//       { time: '7:30', machine: 'machine1', status: 'yellow' },
//       { time: '8:00', machine: 'machine1', status: 'red' },
//       { time: '8:30', machine: 'machine2', status: 'red' },
//       { time: '9:00', machine: 'machine2', status: 'yellow' },
//       { time: '9:30', machine: 'machine2', status: 'green' },
//       { time: '10:00', machine: 'machine3', status: 'yellow' },
//       { time: '10:30', machine: 'machine3', status: 'green' },
//       { time: '11:00', machine: 'machine3', status: 'red' },
//       { time: '11:30', machine: 'machine4', status: 'green' },
//       { time: '12:00', machine: 'machine4', status: 'red' },
//       { time: '12:30', machine: 'machine4', status: 'yellow' },
//       { time: '13:00', machine: 'machine5', status: 'red' },
//       { time: '13:30', machine: 'machine5', status: 'green' },
//       { time: '14:00', machine: 'machine5', status: 'yellow' },
//       // Add more data points as needed
//     ];

//     setData(dummyData);
//   }, []);

//   const getColor = (status) => {
//     switch (status) {
//       case 'off':
//         return 'red';
//       case 'low':
//         return 'yellow';
//       case 'high':
//         return 'green';
//       default:
//         return 'grey';
//     }
//   };

//   return (
//     <ResponsiveContainer width="100%" height={500}>
//       <ScatterChart
//         margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
//       >
//         <CartesianGrid />
//         <XAxis type="category" dataKey="time" name="Time" />
//         <YAxis type="category" dataKey="machine" name="Machine" />
//         <Tooltip cursor={{ strokeDasharray: '3 3' }} />
//         <Scatter name="Production" data={data} fill="#8884d8">
//           {data.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={getColor(entry.status)} />
//           ))}
//         </Scatter>
//       </ScatterChart>
//     </ResponsiveContainer>
//   );
// };

// export default HeatmapChart;
