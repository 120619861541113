import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography, CircularProgress, Card, CardContent } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import '../assets/css/emanagement.css';
import BackButton from './backbutton';
import { useLocation } from 'react-router-dom';
import { apiGetAirQuality } from '../api/api.getaquality';
import { apiGetWaterManagement } from '../api/api.getwatermanagement';
import { useAuthCheck } from '../utils/Auth';

export default function WaterManagement() {
  const [waterManagementData, setWaterManagementData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const location = useLocation();
  const [chartData, setChartData] = useState([]);
  const [dataType, setDataType] = useState('shift');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const { locationId } = location.state || {};
  const [initialLoad, setInitialLoad] = useState(true);
  const [pumpStatus, setPumpStatus] = useState(false); // Example pump status

  const fetchData = async (locationId, dataType, showLoader = false) => {
    
    try {
      if (showLoader) {
        setIsLoading(true);
        setLoadingMessage(`Loading ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`);
      }
      const result = await apiGetWaterManagement(locationId, dataType);
      const chunkedData = result.data.data.slice(0, 10);
      setWaterManagementData(chunkedData);
      const xAxisDates = chunkedData.map((item) => {
        const dateParts = item.dateTimeRecvd.split(' ');
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split('-');
        const [hours, minutes, seconds] = timePart.split(':');
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        return date;
      });
      const sortedData = chunkedData.sort((a, b) => {
        const dateA = new Date(a.dateTimeRecvd.split(' ')[0].split('-').reverse().join('-') + 'T' + a.dateTimeRecvd.split(' ')[1]);
        const dateB = new Date(b.dateTimeRecvd.split(' ')[0].split('-').reverse().join('-') + 'T' + b.dateTimeRecvd.split(' ')[1]);
        return dateA - dateB;
      });
      const xAxisLabels = sortedData.map((item) => {
        const dateParts = item.dateTimeRecvd.split(" ");
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split("-");
        const [hours, minutes, seconds] = timePart.split(":");
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        return date.toLocaleString();
      });

      const updatedChartData = [
        {
          type: "bar",
          xAxis: xAxisLabels,
          data: sortedData.map((item) => parseFloat(item.totalizedWaterFlow)),
        },
      ];
      setChartData(updatedChartData);
      if (showLoader) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching air quality data:", error);
      setError("Failed to fetch data");
      if (showLoader) {
        setIsLoading(false);
      }
    }
  };
  useAuthCheck();
  useEffect(() => {
    if (locationId) {
      fetchData(locationId, dataType, initialLoad);
      const intervalId = setInterval(() => fetchData(locationId, dataType), 10000);
      return () => clearInterval(intervalId);
    }
  }, [locationId, dataType, initialLoad]);

  const handleButtonClick = (type) => {
    setDataType(type);
    setInitialLoad(true);
    fetchData(locationId, type, true);
    setInitialLoad(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={2} alignItems="center" justifyContent='center'>
        <Grid item xs={5} display={'flex'}>
          <BackButton style={{ marginTop: '5px', backgroundColor: 'red' }} />
          <Typography variant="h5" gutterBottom>
            Water Management
          </Typography>
        </Grid>
        <Grid item xs={7} container justifyContent="flex-end">
          {renderButtons(handleButtonClick, dataType)}
        </Grid>
        {isLoading ? (
          <Grid item xs={12} mt={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
            <Typography variant="h6" style={{ marginLeft: '10px' }}>{loadingMessage}</Typography>
          </Grid>
        ) : (
          <>
            {chartData.map((chart, index) => (
              <Grid item xs={4} key={index}>
                <Paper elevation={3} style={{ padding: 20, backgroundColor: 'rgba(3, 3, 62, 0.9)' }}>
                  <Typography variant="h6" gutterBottom style={getChartTitleStyle(chart, index)}>
                    {getChartTitle(chart, index)}
                  </Typography>
                  {renderChart(chart)}
                </Paper>
              </Grid>
            ))}
            <Grid item xs={4}>
              <Card elevation={3} style={{ padding: 20, backgroundColor: 'rgba(3, 3, 62, 0.9)' }}>
                <CardContent>
                  <Typography variant="h6" style={{ color: 'white', fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
                    Water Level Monitoring
                  </Typography>
                  <Typography variant="body1" style={{ color: 'white', fontFamily: 'Arial, sans-serif', marginTop: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: pumpStatus ? 'green' : 'gray', marginRight: 8 }}></div>
                      Pump On
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                      <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: !pumpStatus ? 'red' : 'gray', marginRight: 8 }}></div>
                      Pump Off
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

function getChartTitle(chart, index) {
  if (chart.type === 'line') {
    switch (index) {
      default:
        return `Line Chart ${index + 1}`;
    }
  } else if (chart.type === "bar") {
    switch (index) {
      case 0:
        return "Water Flow";
      default:
        return `Bar Chart ${index + 1}`;
    }
  } else {
    return `Chart ${index + 1}`;
  }
}

function getChartTitleStyle(chart, index) {
  const defaultStyle = { color: 'white', fontFamily: 'Arial, sans-serif' };
  return { ...defaultStyle, fontWeight: 'bold' };
}

function renderChart(chart) {
  if (chart.type === 'bar') {
    return (
      <BarChart
        xAxis={[{ scaleType: 'band', data: chart.xAxis }]}
        series={[{ data: chart.data }]}
        width={450}
        height={200}
      />
    );
  } else {
    return (
      <LineChart
        xAxis={[{ scaleType: 'time', data: chart.xAxis }]}
        series={[{ data: chart.data }]}
        width={450}
        height={200}
      />
    );
  }
}

function renderButtons(handleButtonClick, currentDataType) {
  const buttonLabels = ['Shift', 'Day', 'Week', 'Month', 'Quarter', 'Year'];
  return buttonLabels.map((label, index) => (
    <Button
      key={index}
      variant="contained"
      style={{ margin: '0px 2px', backgroundColor: currentDataType === label.toLowerCase() ? 'rgba(3, 3, 62, 0.9)' : 'gray' }}
      onClick={() => handleButtonClick(label.toLowerCase())}
    >
      {label}
    </Button>
  ));
}
