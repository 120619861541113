import React, { useState, useEffect } from 'react';
import { useAuthCheck } from '../utils/Auth';
import styled, { keyframes } from 'styled-components';
import digineouslogo from '../assets/images/digineous2.png';

const pulse = keyframes`
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 0 rgba(52, 152, 219, 0));
  }
  50% {
    transform: scale(1.05);
    filter: drop-shadow(0 0 10px rgba(52, 152, 219, 0.5));
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 0 rgba(52, 152, 219, 0));
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  font-family: 'Poppins', sans-serif;
  color: #2c3e50;
  text-align: center; /* Center text horizontally */
`;

const Logo = styled.div`
  margin-bottom: 2rem;
  animation: ${pulse} 3s ease-in-out infinite;

  img {
    max-width: 200px;
    height: auto;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  margin: 0; /* Remove extra margin to align better */
  animation: ${fadeIn} 1s ease-out 0.3s backwards;
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin: 0; /* Remove extra margin to align better */
  animation: ${fadeIn} 1s ease-out 0.6s backwards;
`;

const DateTime = styled.div`
  font-size: 1.2rem;
  margin-top: 2rem;
  animation: ${fadeIn} 1s ease-out 0.9s backwards;
`;

export default function Welcome() {
  useAuthCheck();
  const [currentDateTime, setCurrentDateTime] = useState('');

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      setCurrentDateTime(now.toLocaleString());
    };

    updateDateTime();
    const timer = setInterval(updateDateTime, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <WelcomeContainer>
     
      <Title>Welcome To</Title>
      <Subtitle>Digineous Technologies Private Limited</Subtitle>
     
    </WelcomeContainer>
  );
}
