import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import "../assets/css/smartdashboard.css"; // Optional for additional styling
const SmartDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabTitles = [
    "Furnace 2",
    "Cracker 2",
    "Furnace 3",
    "Cracker 3",
    "Stamp",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevActiveTab) => (prevActiveTab + 1) % tabTitles.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [tabTitles.length]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{ width: "100%", maxWidth: 1400, margin: "0 auto", padding: "20px" }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="tabs example"
      >
        {tabTitles.map((title, index) => (
          <Tab
            key={index}
            label={title}
            sx={{
              backgroundColor: activeTab === index ? "#1faec5" : "gray",
              color: activeTab === index ? "black !important" : "white",
              "&:hover": {
                backgroundColor: activeTab === index ? "#1faec5" : "lightgray",
              },
              fontSize: "20px",
            }}
          />
        ))}
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {tabTitles.map((_, index) => (
          <TabPanel key={index} value={activeTab} index={index}>
            <Box
              sx={{
                width: "calc(25% - 10px)",
                margin: "5px",
                padding: "10px",
                border: "4px solid #ccc",
                textAlign: "center",
                backgroundColor: "#fafafa",
                boxSizing: "border-box",
              }}
            >
              <Typography style={{ fontSize: "55px" }}>
                {" "}
                Box 1 - Temp 1°C{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(25% - 10px)",
                margin: "5px",
                padding: "10px",
                border: "4px solid #ccc",
                textAlign: "center",
                backgroundColor: "#fafafa",
                boxSizing: "border-box",
              }}
            >
              <Typography style={{ fontSize: "55px" }}>
                {" "}
                Box 1 - Temp 1°C{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(25% - 10px)",
                margin: "5px",
                padding: "10px",
                border: "4px solid #ccc",
                textAlign: "center",
                backgroundColor: "#fafafa",
                boxSizing: "border-box",
              }}
            >
              <Typography style={{ fontSize: "55px" }}>
                {" "}
                Box 1 - Temp 1°C{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(25% - 10px)",
                margin: "5px",
                padding: "10px",
                border: "4px solid #ccc",
                textAlign: "center",
                backgroundColor: "#fafafa",
                boxSizing: "border-box",
              }}
            >
              <Typography style={{ fontSize: "55px" }}>
                {" "}
                Box 1 - Temp 1°C{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(25% - 10px)",
                margin: "5px",
                padding: "10px",
                border: "4px solid #ccc",
                textAlign: "center",
                backgroundColor: "#fafafa",
                boxSizing: "border-box",
              }}
            >
              <Typography style={{ fontSize: "55px" }}>
                {" "}
                Box 1 - Temp 1°C{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(25% - 10px)",
                margin: "5px",
                padding: "10px",
                border: "4px solid #ccc",
                textAlign: "center",
                backgroundColor: "#fafafa",
                boxSizing: "border-box",
              }}
            >
              <Typography style={{ fontSize: "55px" }}>
                {" "}
                Box 1 - Temp 1°C{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(25% - 10px)",
                margin: "5px",
                padding: "10px",
                border: "4px solid #ccc",
                textAlign: "center",
                backgroundColor: "#fafafa",
                boxSizing: "border-box",
              }}
            >
              <Typography style={{ fontSize: "55px" }}>
                {" "}
                Box 1 - Temp 1°C{" "}
              </Typography>
            </Box>{" "}
            <Box
              sx={{
                width: "calc(25% - 10px)",
                margin: "5px",
                padding: "10px",
                border: "4px solid #ccc",
                textAlign: "center",
                backgroundColor: "#fafafa",
                boxSizing: "border-box",
              }}
            >
              <Typography style={{ fontSize: "55px" }}>
                {" "}
                Box 1 - Temp 1°C{" "}
              </Typography>
            </Box>
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

export default SmartDashboard;
