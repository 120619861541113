import React from "react";
import { Grid, Box } from "@mui/material";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from "recharts";

const radialData = [
  { name: "18-24", uv: 31.47, fill: "#8884d8" },
  { name: "25-29", uv: 26.69, fill: "#83a6ed" },
  { name: "30-34", uv: 15.69, fill: "#8dd1e1" },
  { name: "35-39", uv: 8.22, fill: "#82ca9d" },
  { name: "40-49", uv: 8.63, fill: "#a4de6c" },
  { name: "50+", uv: 2.63, fill: "#d0ed57" },
  { name: "unknow", uv: 6.67, fill: "#ffc658" },
];

const barData = [
  { name: "Page A", uv: 4000, pv: 2400 },
  { name: "Page B", uv: 3000, pv: 1398 },
  { name: "Page C", uv: 2000, pv: 9800 },
  { name: "Page D", uv: 2780, pv: 3908 },
  { name: "Page E", uv: 1890, pv: 4800 },
  { name: "Page F", uv: 2390, pv: 3800 },
  { name: "Page G", uv: 3490, pv: 4300 },
];

const horizontalBarData = [
  { name: "A", uv: 4000, pv: 2400 },
  { name: "B", uv: 3000, pv: 1398 },
  { name: "C", uv: 2000, pv: 9800 },
  { name: "D", uv: 2780, pv: 3908 },
  { name: "E", uv: 1890, pv: 4800 },
  { name: "F", uv: 2390, pv: 3800 },
  { name: "G", uv: 3490, pv: 4300 },
];

const ChartComponent = () => {
  return (
    <Grid container spacing={2} mt={2} padding={2}>
      {/* First Row - Three Charts */}
      <Grid item xs={4}>
        <Box sx={{ height: 300, backgroundColor: "#0d1d31" }}>
          <ResponsiveContainer width="100%" height="100%">
            <RadialBarChart
              cx="50%"
              cy="50%"
              innerRadius="10%"
              outerRadius="80%"
              barSize={10}
              data={radialData}
            >
              <RadialBar
                minAngle={15}
                label={{ position: "insideStart", fill: "#fff" }}
                background
                clockWise
                dataKey="uv"
              />
              <Legend
                iconSize={10}
                layout="horizontal"
                verticalAlign="bottom"
              />
            </RadialBarChart>
          </ResponsiveContainer>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ height: 300, backgroundColor: "#0d1d31" }}>
          <ResponsiveContainer width="100%" height="100%">
            <RadialBarChart
              cx="50%"
              cy="50%"
              innerRadius="10%"
              outerRadius="80%"
              barSize={10}
              data={radialData}
            >
              <RadialBar
                minAngle={15}
                label={{ position: "insideStart", fill: "#fff" }}
                background
                clockWise
                dataKey="uv"
              />
              <Legend
                iconSize={10}
                layout="horizontal"
                verticalAlign="bottom"
              />
            </RadialBarChart>
          </ResponsiveContainer>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ height: 300, backgroundColor: "#0d1d31" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={barData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8" />
              <Bar dataKey="uv" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Grid>

      {/* Second Row - Two Horizontal Bar Charts */}
      <Grid item xs={4}>
        <Box sx={{ height: 300, backgroundColor: "#0d1d31" }}>
          <ResponsiveContainer width="100%" height={280}>
            <BarChart
              layout="vertical"
              data={horizontalBarData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis type="category" dataKey="name" />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8">
                <LabelList dataKey="pv" position="insideRight" />
              </Bar>
              <Bar dataKey="uv" fill="#82ca9d">
                <LabelList dataKey="uv" position="insideRight" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ height: 300, backgroundColor: "#0d1d31" }}>
          <ResponsiveContainer width="100%" height={280}>
            <BarChart
              layout="vertical"
              data={horizontalBarData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis type="category" dataKey="name" />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8">
                <LabelList dataKey="pv" position="insideRight" />
              </Bar>
              <Bar dataKey="uv" fill="#82ca9d">
                <LabelList dataKey="uv" position="insideRight" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChartComponent;
