import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  TextField,
  Button,
  Grid,
  tableCellClasses,
  styled,
  IconButton,
  TablePagination,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import EditIcon from "@mui/icons-material/Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import styled from "styled-components";
import { parseISO, format } from "date-fns";
import { apiHourlyBucket2 } from "../api/api.hourlybucket2";
import { apiGetShift } from "../api/api.getshift";
import { apigetLines } from "../api/api.getline";
import { apigetMachine } from "../api/apigetmachine";
import { useAuthCheck } from "../utils/Auth";
import DownloadButton from "../utils/DownloadButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function HourlyBucketM2() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [hourlyBucket2, setHourlyBucket2] = useState({
    lineNo: "",
    machineId: "",
    fromDate: "23:01:1997",
    shiftNo: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [severity, setSeverity] = useState("success");
  const [selectedLine, setSelectedLine] = useState("");
  const [data, setData] = useState([]);

  const [error, setError] = useState(null);

  const [shiftData, setShiftData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleEditClick = () => {
    // setAddOpen(true)
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };
  useAuthCheck();
  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("Result data machine:", result.data.data); // Log the data property
        setMachineData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getmachine();
  }, [refreshData]);
  useEffect(() => {
    const getLine = async () => {
      try {
        const result = await apigetLines();
        console.log("Result data line:", result.data.data); // Log the data property
        setLineData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getLine();
  }, [refreshData]);
  useEffect(() => {
    const getShift = async () => {
      try {
        const result = await apiGetShift();
        console.log("shiftdata", result.data.data);
        setShiftData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getShift();
  }, [refreshData]);
  const handleInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target;
    setHourlyBucket2((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };
  const filteredMachines = machineData.filter(
    (machine) => machine.lineNo === selectedLine
  );
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formattedFromDate = format(
        parseISO(hourlyBucket2.fromDate),
        "dd-MMM-yyyy"
      );

      console.log(
        "todate,fromdate,machineid,lineid:",

        formattedFromDate
      );
      const formattedRawData = {
        ...hourlyBucket2,
        fromDate: formattedFromDate,
      };
      // setAddOpen(false);
      console.log("hourly m2 data:", formattedRawData);
      const result = await apiHourlyBucket2(formattedRawData);

      // await getmachine();
      handleSnackbarOpen("Raw Data fetched successfully!", "success"); // Pass severity as "success"
      // setLoading(false);
      console.log("hourly m2 response", result.data);
      setData(result.data);
      setRefreshData((prev) => !prev);
    } catch (error) {
      // setLoading(false);
      console.error("Error getting raw data:", error);
      handleSnackbarOpen("Error fetching raw data. Please try again.", "error"); // Pass severity as "error"
    } finally {
      setLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const downloadApiCall = async () => {
    const { lineNo, machineId, fromDate, shiftNo } = hourlyBucket2; // Use the current state values
    const formattedFromDate = format(parseISO(fromDate), "dd-MMM-yyyy");

    return await apiHourlyBucket2({
      lineNo,
      machineId,
      fromDate: formattedFromDate,
      shiftNo,
    });
  };
  
  const formatData = (data) => {
    return data.map((row) => ({
      "M Id": row.machineID != null ? parseInt(row.machineID) : "",
      "Date Time": row.dateTime ?? "",
      VAT: row.vat != null ? parseFloat(row.vat) : "",
      "Avg CT": row.avgSct != null ? parseFloat(row.avgSct) : "",
      "U Loss": row.uLoss != null ? parseFloat(row.uLoss) : "",
      "Revised U Loss": row.revisedULoss != null ? parseFloat(row.revisedULoss) : "",
      "U%": row.uPer != null ? parseFloat(row.uPer) : "",
      "A Loss": row.aLoss != null ? parseFloat(row.aLoss) : "",
      "Revised A Loss": row.revisedALoss != null ? parseFloat(row.revisedALoss) : "",
      "A%": row.aPer != null ? parseFloat(row.aPer) : "",
      "P Loss": row.pLoss != null ? parseFloat(row.pLoss) : "",
      "P %": row.pPer != null ? parseFloat(row.pPer) : "",
      "Q Loss ": row.qLoss != null ? parseFloat(row.qLoss) : "",
      "Q% ": row.qPer != null ? parseFloat(row.qPer) : "",
      Total: row.total != null ? parseFloat(row.total) : "",
      "OPEC1% ": row.opeC1 != null ? parseFloat(row.opeC1) : "",
      "OPEC2% ": row.opeC2 != null ? parseFloat(row.opeC2) : "",
      "OEE%": row.oee != null ? parseFloat(row.oee) : "",
    }));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  return (
    <div style={{ padding: "0px 20px", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingTop: "5px",
          paddingBottom: "10px",
        }}
      >
        <h2>Hour Bucket (M2)</h2>
      </div>
      <Grid
        container
        spacing={2}
        style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
      >
        {" "}
        {/* Set alignItems to center items vertically */}
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              name="fromDate"
              label="Select Date"
              type="date"
              // defaultValue="2024-03-20T09:00"
              value={hourlyBucket2?.fromDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          {" "}
          {/* Adjust item size for different screen sizes */}
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Plant</InputLabel>
            <Select
              name="lineNo"
              value={hourlyBucket2?.lineNo}
              onChange={handleInputChange}
            >
              {lineData.map((line) => (
                <MenuItem key={line.id} value={line.lineNo}>
                  {line.lineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          {" "}
          {/* Adjust item size for different screen sizes */}
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Machine</InputLabel>
            <Select
              name="machineId"
              value={hourlyBucket2?.machineId}
              onChange={handleInputChange}
            >
              {filteredMachines.map((machine) => (
                <MenuItem key={machine.id} value={machine.machineId}>
                  {machine.displayMachineName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          {" "}
          {/* Adjust item size for different screen sizes */}
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Shift</InputLabel>
            <Select
              name="shiftNo"
              value={hourlyBucket2?.value}
              onChange={handleInputChange}
            >
              <MenuItem value="1">Shift A</MenuItem>
              <MenuItem value="2">Shift B</MenuItem>
              <MenuItem value="3">Shift C</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {" "}
          {/* Adjust item size for different screen sizes */}
          <Button variant="contained" color="primary" onClick={handleAddSubmit}>
            OK
          </Button>
        </Grid>
      </Grid>
      <DownloadButton
        apiCall={downloadApiCall}
        formatData={formatData}
        fileName="HourlyBucket(M2).xlsx"
      />
      <Box sx={{ marginTop: "20px", maxHeight: "500px", overflow: "auto" }}>
        {/* <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <Button
              onClick={() => setAddOpen(true)}
              style={{
                fontWeight: "500",
                borderRadius: "4px",
                color: "gray",
                border: "2px solid gray",
                padding: "5px",
                marginBottom: "5px",
              }}
            >
              {" "}
              Add New &nbsp;{" "}
              <FontAwesomeIcon style={{ fontSize: "18px",color:"gray" }} icon={faPlus} />
            </Button>
          </div> */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell">MID</StyledTableCell>
                <StyledTableCell className="table-cell">
                
                  Date Time
                </StyledTableCell>
                <StyledTableCell className="table-cell">VAT</StyledTableCell>
                <StyledTableCell className="table-cell">Avg CT</StyledTableCell>
                <StyledTableCell className="table-cell">U Loss</StyledTableCell>
                <StyledTableCell className="table-cell">
                  Revised U Loss
                </StyledTableCell>
                <StyledTableCell className="table-cell">U%</StyledTableCell>
                <StyledTableCell className="table-cell">A Loss</StyledTableCell>
                <StyledTableCell className="table-cell">
                  Revised A Loss
                </StyledTableCell>
                <StyledTableCell className="table-cell">A%</StyledTableCell>
                <StyledTableCell className="table-cell">P Loss</StyledTableCell>
                <StyledTableCell className="table-cell">P%</StyledTableCell>
                <StyledTableCell className="table-cell">Q Loss</StyledTableCell>
                <StyledTableCell className="table-cell">Q %</StyledTableCell>
                <StyledTableCell className="table-cell">Total</StyledTableCell>
                <StyledTableCell className="table-cell">OPE%</StyledTableCell>
            
                <StyledTableCell className="table-cell">OEE%</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className="table-cell">
                      {row.machineID}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.dateTime}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.vatM2}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.avgVatM2}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.uLoss}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.revisedULoss}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.uPer}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.aLoss}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.revisedALoss}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.aPer}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.pLossM2}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.pPer}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.qLoss}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.qPer}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.total}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.opeC1}
                    </StyledTableCell>
                   
                    <StyledTableCell className="table-cell">
                      {row.oee}
                    </StyledTableCell>

                    {/* <StyledTableCell  className="table-cell">
                    <IconButton onClick={() => handleDeleteClick(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell > */}
                  </StyledTableRow>
                ))}
              {/* {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 }}>
                <StyledTableCell colSpan={8} style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {`No further data available`}
                    </div>
                </StyledTableCell>
              </StyledTableRow>
            )} */}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 1000]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
