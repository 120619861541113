import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import solarimg from "../assets/images/sunenergy.jpg";
import evimg from "../assets/images/ev2.jpg";
import wmimg from "../assets/images/watermanagement.jpg";
import stp from "../assets/images/stp.jpg";
import fooddecomp from "../assets/images/fooddecom.jpg";
import { BarChart } from "@mui/x-charts/BarChart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../assets/css/facilitysus.css";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";
import GaugeWithPointer from "./gaugewithpointer";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import BackButton from "./backbutton";
import { useAuthCheck } from "../utils/Auth";

const StyledBarChart = styled(BarChart)(({ theme }) => ({
  "& .MuiBar-root rect": {
    x: 0,
    y: -0,
    width: 20,
    height: 20,
    fill: "rgb(46, 150, 255)",
  },
  "& .MuiBarLabel-root": {
    fill: "white",
    color: "white",
  },
}));
export default function FacilitySustainability() {
  useAuthCheck();
  const location = useLocation();
  const { place, locationData, locationId } = location.state || {};
  // console.log(place, "from prev", location.state,);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  // Determine gauge width and height based on screen size
  const gaugeWidth = isSmallScreen ? 100 : 150;
  const gaugeHeight = isSmallScreen ? 100 : 150;

  function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();

    if (valueAngle === null) {
      // No value to display
      return null;
    }

    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="red" />
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="red"
          strokeWidth={3}
        />
      </g>
    );
  }
  const {
    waterFlow,
    temperature,
    humidity,
    co2,
    voltageR,
    currentR,
    fuelLevel1,
    fuelLevel2,
    gKwh2,
    gKwh1,
    dg1,
    dg2,pf
  } = locationData[0];

  const handleEnegyManagementClick = () => {
    navigate("/energymanagement", { state: { locationId } });
  };
  const handleGensetClick = () => {
    navigate("/dieselgenset", { state: { locationId } });
  };
  const handleAirQaulityClick = () => {
    navigate("/airquality", { state: { locationId } });
  };
  const handleWaterManagementClick = () => {
    navigate("/watermanagement", { state: { locationId } });
  };


  return (
    <div
      style={{
        padding: "0px 20px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundColor: "#E8E8E8",
      }}
    >
      <Grid item xs={5} display={"flex"} alignItems="center" mt={2}>
        <BackButton />
        <Typography variant="h5" gutterBottom>
          {location?.state.place} Facility
        </Typography>
      </Grid>
      <div style={{ marginTop: "20px" }}>
        <Grid container spacing={9} display={"flex"}>
          {/* Box 1: Voltage and Current */}

          <Grid item md={4} sm={4}>
            <button
              onClick={handleEnegyManagementClick}
              style={{ cursor: "pointer", border: "0px" }}
            >
              <Paper sx={{ p: 2 }}>
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Energy Management
                </Typography>
                <hr />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {/* Replace with GaugeWithPointer for Energy */}
                    <GaugeContainer
                      width={180}
                      height={150}
                      startAngle={-110}
                      endAngle={110}
                      value={currentR}
                      aria-valuenow={currentR}
                      aria-valuetext="current "
                      innerRadius="65%"
                      outerRadius="100%"
                    >
                      <GaugeReferenceArc />
                      <GaugeValueArc />
                      <GaugePointer />
                    </GaugeContainer>
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ fontWeight: "600", color: "white" ,fontSize:'16px'}}
                      className="engeryCurrentLabel"
                    >
                      {currentR} KWH
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <GaugeContainer
                      width={180}
                      height={150}
                      startAngle={-110}
                      endAngle={110}
                      value={voltageR}
                      valueMin={0}
                      valueMax={400}
                      innerRadius="65%"
                      outerRadius="100%"
                    >
                      <GaugeReferenceArc />
                      <GaugeValueArc />
                      <GaugePointer />
                    </GaugeContainer>
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ fontWeight: "600", color: "white" ,fontSize:'16px'}}
                    >
                      {pf} Power Factor
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    marginTop={2}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    {/* <Grid item xs={3.5}> */}
                    <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                      <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
                        <Typography
                          variant="body2"
                          align="center"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            background: "lightblue",
                            padding:'0px 5px 0px 5px'
                          }}
                        >
                          Current
                        </Typography>
                        <hr />

                        <Typography
                          variant="body2"
                          align="center"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            background: "white",
                            color: "red",
                          }}
                        >
                          {currentR} A
                        </Typography>
                      </Paper>
                    {/* </Grid> */}
                    {/* <Grid item xs={3.5}> */}
                      <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
                        <Typography
                          variant="body2"
                          align="center"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            background: "lightblue",
                            padding:'0px 5px 0px 5px'
                          }}
                        >
                          Voltage
                        </Typography>
                        <hr />

                        <Typography
                          variant="body2"
                          align="center"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            background: "white",
                            color: "red",
                            padding:'0px 5px 0px 5px'
                          }}
                        >
                          {voltageR} V
                        </Typography>
                      </Paper>
                    {/* </Grid> */}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </button>
          </Grid>

          {/* Box 2: Energy and Fuel Level */}

          <Grid justifyContent="center" alignItems="center" item xs={4}>
            <button
              onClick={handleGensetClick}
              style={{ border: "0px", cursor: "pointer" }}
            >
              <Paper sx={{ p: 2 }} className="dieselGensetBox">
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Diesel Genset
                </Typography>
                <hr />

                <Grid container spacing={2}>
                  {/* Energy Chart */}
                  <Grid item xs={6}>
                    <GaugeContainer
                      width={180}
                      height={150}
                      value={gKwh1}
                      startAngle={-110}
                      endAngle={110}
                      innerRadius="65%"
                      outerRadius="100%"
                      fill="#FF0000"
                      valueMin={0}
                      valueMax={2000}
                    >
                      <GaugeReferenceArc />
                      <GaugeValueArc />
                      <GaugePointer />
                    </GaugeContainer>
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ fontWeight: "600", color: "white" ,fontSize:'16px'}}
                      className="gensetEnergyLabel"
                    >
                      {gKwh1} Energy(kwh1)
                    </Typography>
                  </Grid>
                  {/* Fuel Level Chart */}
                  <Grid item xs={6}>
                    {/* <Typography
                      variant="body2"
                      align="center"
                      style={{ fontWeight: "600", color: "white" ,fontSize:'16px'}}
                    >
                      Fuel Level
                    </Typography> */}
                    <StyledBarChart
                      xAxis={[
                        {
                          scaleType: "band",
                          data: ["F L 1", "F L 2"],
                        },
                      ]}
                      series={[
                        {
                          data: [fuelLevel1],
                          label: "Fuel Level ",
                          id: "pvId",
                        },
                        {
                          data: [fuelLevel2],
                          // label: 'Fuel Level 2',
                          id: "uvId",
                        },
                      ]}
                      width={200}
                      height={185}
                    />
                  </Grid>
                  {/* Temperature and Humidity Boxes */}
                  {/* First row */}
                  {/* <Grid item xs={3}> */}
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          background: "lightblue",
                          padding:'0px 5px 0px 5px'
                        }}
                      >
                        kwh1
                      </Typography>
                      <hr />
                      <Typography
                        variant="body2"
                        align="center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          background: "white",
                          color: "red",
                          padding:'0px 5px 0px 5px'

                        }}
                      >
                        {gKwh1} 
                      </Typography>
                    </Paper>
                    {/* </Grid> */}
                    {/* <Grid item xs={3}> */}
                    <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          background: "lightblue",
                          padding:'0px 5px 0px 5px'
                        }}
                      >
                        kwh2
                      </Typography>
                      <hr />
                      <Typography
                        variant="body2"
                        align="center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          background: "white",
                          color: "red",
                          padding:'0px 5px 0px 5px'

                        }}
                      >
                        {gKwh2} 
                      </Typography>
                    </Paper>
                    {/* </Grid> */}
                    {/* Second row */}
                    {/* <Grid item xs={3}> */}
                    <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          background: "lightblue",
                          padding:'0px 5px 0px 5px'
                        }}
                      >
                        DG1
                      </Typography>
                      <hr />
                      <Typography
                        variant="body2"
                        align="center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          background: dg1==="ON"?"darkgreen":"red",
                          color: "white", 
                        }}
                      >
                       {dg1}
                      </Typography>
                    </Paper>
                    {/* </Grid> */}
                    {/* <Grid item xs={3}> */}
                    <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          background: "lightblue",
                          padding:'0px 5px 0px 5px'
                        }}
                      >
                        DG2
                      </Typography>
                      <hr />
                      <Typography
                        variant="body2"
                        align="center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          background: dg2==="ON"?"darkgreen":"red",
                          color: "white", 
                          
                        }}
                      >
                       {dg2}
                      </Typography>
                    </Paper>
                  </div>
                  {/* </Grid> */}
                </Grid>
              </Paper>
            </button>
          </Grid>

          {/* Box 3: Air Quality */}
          <Grid
            item
            md={4}
            sm={4}
            xs={4}

            // justifyContent="center"
            // alignItems="center"

            // style={{display:'flex',justifyContent:'center'}}
            //  style={{backgroundColor:'red'}}
          >
            <button
              onClick={handleAirQaulityClick}
              style={{ cursor: "pointer", border: "0px" }}
              className="AQButton"
            >
              <Paper
                style={{ width: "360px" }}
                sx={{ p: 2 }}
                className="airQualityBox"
              >
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Air Quality(CO2)
                </Typography>
                <hr />

                {/* Air Quality Chart */}
                <Grid
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <GaugeContainer
                    width={180}
                    height={150}
                    startAngle={-110}
                    endAngle={110}
                    valueMin={0}
                    valueMax={999}
                    value={co2}
                    innerRadius="65%"
                    outerRadius="100%"
                  >
                    <GaugeReferenceArc
                      style={{ color: "red", backgroundColor: "#FF0000" }}
                    />
                    <GaugeValueArc
                      style={{ color: "red", backgroundColor: "#FF0000" }}
                    />
                    <GaugePointer />
                  </GaugeContainer>
                  {/* Air Quality Label */}
                  <Typography
                    variant="body2"
                    align="center"
                    style={{ fontWeight: "600", color: "white" ,fontSize:'16px'}}
                  >
                    {co2} PPM
                  </Typography>
                </Grid>
                {/* Temperature and Humidity Boxes */}
                <Grid
                  container
                  marginTop={2}
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  {/* <Grid item xs={3.5}> */}
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                  <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
                    <Typography
                      variant="body2"
                      align="center"
                      style={{
                        fontSize: "15px", // Adjust font size based on screen size
                        fontWeight: "600",
                        background: "lightblue",
                        padding:'0px 5px 0px 5px'
                      }}
                    >
                      Temperature
                    </Typography>
                    <hr />

                    <Typography
                      variant="body2"
                      align="center"
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        background: "white",
                        color: "red",
                      }}
                    >
                      {temperature}°C
                    </Typography>
                  </Paper>
                  {/* </Grid> */}
                  {/* <Grid item xs={3.5}> */}
                  <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
                    <Typography
                      variant="body2"
                      align="center"
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        background: "lightblue",
                        padding:'0px 5px 0px 5px'
                      }}
                    >
                      Humidity
                    </Typography>
                    <hr />

                    <Typography
                      variant="body2"
                      align="center"
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        background: "white",
                        color: "red",
                      }}
                    >
                      {humidity}%
                    </Typography>
                  </Paper>
                  </div>
                {/* </Grid> */}
                </Grid>
              </Paper>
            </button>
          </Grid>
        </Grid>
        <Grid
          marginTop={2}
          container
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* Replace '/your-path' with your desired URL */}
          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
          >
            <Link to="https://monitoring.solaredge.com/solaredge-web/p/login?locale=en_GB-path">
              <Paper>
                {/* Image inside Paper component */}
                <Typography
                  variant="body2"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                    background: "rgba(3, 3, 62, 0.9 )",
                  }}
                >
                  Solar
                </Typography>

                <img
                  src={solarimg} // Use the imported image
                  alt="Local Image"
                  style={{ width: "180px", height: "180px", display: "block" }}
                />
              </Paper>
            </Link>
          </Grid>
          {/* Empty Box 5 */}
          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
          >
            <Link to="http://cms.kazam.in/auth/login">
              <Paper>
                {/* Image inside Paper component */}
                <Typography
                  variant="body2"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                    background: "rgba(3, 3, 62, 0.9 )",
                  }}
                >
                  EV
                </Typography>

                <img
                  src={evimg} // Use the imported image
                  alt="Local Image"
                  style={{ width: "100%", height: "180px", display: "block" }}
                />
              </Paper>
            </Link>
          </Grid>
          
          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
            style={{ cursor: "pointer" }}
            onClick={handleWaterManagementClick}
            
          >
            
              <Paper >
                {/* Image inside Paper component */}
                <Typography
                  variant="body2"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                    background: "rgba(3, 3, 62, 0.9 )",
                  }}
                >
                  Water Management
                </Typography>

                <img
                  src={wmimg} // Use the imported image
                  alt="Local Image"
                  style={{ width: "100%", height: "180px", display: "block" }}
                  // onClick={handleWaterManagementClick}
                  
                />
              </Paper>
           
          </Grid>
          
          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
          >
            <Link to="/stpmanagement">
              <Paper>
                {/* Image inside Paper component */}
                <Typography
                  variant="body2"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                    background: "rgba(3, 3, 62, 0.9 )",
                  }}
                >
                  STP
                </Typography>

                <img
                  src={stp} // Use the imported image
                  alt="Local Image"
                  style={{ width: "100%", height: "180px", display: "block" }}
                />
              </Paper>
            </Link>
          </Grid>
          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
          >
            <Link to="https://flowlinc.io">
              <Paper>
                {/* Image inside Paper component */}
                <Typography
                  variant="body2"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                    background: "rgba(3, 3, 62, 0.9 )",
                  }}
                >
                  Food Decomposer
                </Typography>

                <img
                  src={fooddecomp} // Use the imported image
                  alt="Local Image"
                  style={{ width: "100%", height: "180px", display: "block" }}
                />
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
